import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { SharedobjsService } from '../../services/sharedobjs.service';
import { UtilService } from '../../services/util.service';
import { ModalController } from '@ionic/angular';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-share-quiz',
  templateUrl: './share-quiz.component.html',
  styleUrls: ['./share-quiz.component.scss'],
})
export class ShareQuizComponent implements OnInit {
  @Input() quiz: any;
  @Input() action;
  query: string;
  users: any = [];
  selectedUsers: any = [];
  quizzes: any = [];

  constructor(
    private sharedObjsService: SharedobjsService,
    private util: UtilService,
    private modalCtrl: ModalController,
    private userService: UserService
  ) { }

  ngOnInit() {
    // console.log('quiz: ', this.quiz)
    // console.log('action: ', this.action)
  }

  closeModal() {
		this.modalCtrl.dismiss();
  }

  loadSearchMember(e){
		this.query = e.target.value;
		if(this.query){
			this.userService.searchUsers(this.query).subscribe((resp:any)=>{
				if(resp.success)
          this.users = resp.data
			})
		}
  }
  
  selectShareQuiz(user_id, isChecked){
    if(isChecked) {
      this.selectedUsers.push(user_id);
    } else {
      var filtered = this.selectedUsers.filter(function(el) { return el.user_id != user_id; }); 
      this.selectedUsers = filtered;
    }
  }

  shareQuiz(){
    if(this.selectedUsers.length > 0){

      var content = []
      this.quiz.forEach((q: any) => {
        content.push(
          {
            'id': q._id,
            'name': q.name,
            'send_question_limit': q.send_question_limit,
            'time_limit': q.time_limit,
            'questions': q.questions.map(function (question: any) {
                return {
                    'correct_answer': question.correct_answer,
                    'html': question.html,
                    'options': question.options,
                    'question': question.question,
                    'type': question.type
                }
            })
          }
        )
      })

      var quiz_id = undefined
      if(this.action == 'share_co_teaching'){
        quiz_id = this.quiz[0]._id
      }

      var data = {
        type: 'quiz',
        content: content
      };

      if(content.length > 0){
        this.sharedObjsService.saveObj(data).subscribe((resp: any) => {
          if(resp && resp.success){
            this.sharedObjsService.shareObj({
                id: resp.data._id, 
                users: this.selectedUsers, 
                co_teaching: this.action == 'share_co_teaching',
                quiz_id: quiz_id
              }).subscribe((resp: any) => {
                this.util.showMessage(resp);
                if(resp && resp.success){
                  this.closeModal()
                }
            })
          }
        })
      }

    } else {
      this.util.showError("You need to choose one or more users.");
    }
  }

}
