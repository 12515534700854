import { NgModule } from '@angular/core';
import { SearchPipe } from './search.pipe';
import { SortPipe } from './sort.pipe';
import { SearchKeyPipe } from './search-key.pipe';
import { CalculatePercentagePipe } from './calculate-percentage.pipe';
import { DateSuffixPipe } from './date-suffix.pipe';
import { SecondsToTimePipe } from './seconds-to-time.pipe';
import { StripTagsPipe } from './strip-tags.pipe';
import { NumberSuffixPipe } from './number-suffix.pipe';
import { SafeUrlPipe } from './safe-url.pipe';
@NgModule({
	declarations: [SearchPipe,
    SortPipe,
    SearchKeyPipe,
    CalculatePercentagePipe,
    DateSuffixPipe,
    SecondsToTimePipe,
    StripTagsPipe,
    NumberSuffixPipe,
    SafeUrlPipe,
  ],
	imports: [],
	exports: [SearchPipe,
    SortPipe,
    SearchKeyPipe,
    DateSuffixPipe,
    NumberSuffixPipe,
    CalculatePercentagePipe,
    SecondsToTimePipe,
    StripTagsPipe,
    SafeUrlPipe
  ]
})
export class PipesModule {

	static forRoot() {
      return {
          ngModule: PipesModule,
          providers: [],
      };
   }
}