import { Component, OnInit } from '@angular/core';
import { Events, Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { FCM } from '@ionic-native/fcm/ngx';
import { UtilService } from './services/util.service';

import { AuthenticationService, User } from './services/authentication.service';
import { UserService } from './services/user.service';
import { OptionServiceService, Options } from './services/option-service.service';
import { Router, RouterEvent, ActivatedRoute } from '@angular/router';
import { Push, PushObject, PushOptions } from '@ionic-native/push/ngx';
import { PushNotificationService } from './services/push-notification.service';
import { DownloadAppComponent } from './components/download-app/download-app.component';
import { ModalController } from '@ionic/angular';
import { faCoffee } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent{
  faCoffee = faCoffee;
  authCode;
  constructor(
    private modalController: ModalController,
    private optionsService: OptionServiceService,
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private authService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    private push: Push,
    private pushNotificationService: PushNotificationService,
    private fcm: FCM,
    private util: UtilService,
    private userService: UserService
  ) {
    
  }

  ngOnInit() { 
    // console.log("Check Started");
    var token = window.location.href.split("?code=");
    if(!this.util.isObjectEmpty(token[1])){
      this.util.setStorage(this.util.authCode,token[1])
      this.authCode = token[1];
    }
    this._checkOptions();
  }

  _checkOptions(){
    this.optionsService.getOptionsSubject().subscribe((options:any)=>{
      if(options)
        this._checkBrowser(options);
    })
  }

  _checkBrowser(options){
    if (this.platform.is('desktop')) {
      this.initializeApp();
    } else if (this.platform.is('ios')) {
      this.openDownloadAppModal('ios',options.platform['ios'].download_browser);
    } else if (this.platform.is('android')) {
      this.openDownloadAppModal('android',options.platform['android'].download_browser);
    }else{
      this.initializeApp();
    }
  }

  async openDownloadAppModal(platform,platform_url){
    const modal = await this.modalController.create({
      component: DownloadAppComponent,
      componentProps: {platform,platform_url}
    });

    await modal.present();

    const { data } = await modal.onWillDismiss();
    if(data)
      this.initializeApp();

  }

  initializeApp() {
    // console.log("Initializing App");
    var that = this;
    this.platform.ready().then(() => {
      // this.statusBar.styleDefault();
      // setTimeout(() => { 
      //   this.splashScreen.hide();
      // }, 10000);
      // this.splashScreen.hide();
      this.authService.authenticationState.subscribe(state =>{
        if(state){
          this.router.navigate(['members','l','dashboard'],{ replaceUrl: true });
        }else{
          if(this.authCode)
            this.router.navigate(['login'],{state:{'authCode': this.authCode}});
          else{
            var userConfirmed = this.util.sessionStorage.get(this.util.userConfirmed);
            if(userConfirmed)
              this.router.navigate(['login']);
            else
              this.userService.isThatYou().subscribe((resp:any)=>{
                if(resp.success && resp.user && resp.user.first_name){
                  this.router.navigate(['member-confirm'],{state:{'user': resp.user}});
                }else{
                  this.router.navigate(['login']);
                }
              })
          }
        }
      })

      // this.statusBar.overlaysWebView(false);
      // this.statusBar.backgroundColorByHexString('#ffffff');
      // console.log("testing fmc");
      // this.fcm.getToken().then(token => { 
      //   console.log(token);
      //   that.util.setStorage(that.util.pushID, token);
      //   console.log("========this.fcm.getToken()");
      //   console.log(token);
      // });
      // this.fcm.onTokenRefresh().subscribe(token => { 
      //   console.log("========this.fcm.onTokenRefresh()");
      //   console.log(token); 
      // });
      // this.fcm.onNotification().subscribe(data => {
      //   console.log("========this.fcm.onNotification()");
      //   console.log(data);
      //   if (data.wasTapped) {
      //     console.log('Received in background');
      //   } else {
      //     console.log('Received in foreground');
      //   }
      // });

      // this.pushSetup();
      // this.pushNotificationService.init();
    });
  }

  // pushSetup() {
  //   const options: PushOptions = {
  //     android: { 
  //       // senderID: '480499725053'
  //     },
  //     ios: {
  //       alert: 'true',
  //       badge: true,
  //       sound: 'false'
  //     }
  //   };
    
  //   const pushObject: PushObject = this.push.init(options);
  //   pushObject.on('notification').subscribe((notification: any) => console.log('Received a notification', notification));
  //   pushObject.on('registration').subscribe((registration: any) => console.log('Device registered', registration));
  //   pushObject.on('error').subscribe(error => console.error('Error with Push plugin', error));
  // }
}
