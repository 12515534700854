import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { AuthenticationService, User } from './../../services/authentication.service';
import { Router, RouterEvent } from '@angular/router';

@Component({
  selector: 'app-profile-pitcure-popover',
  templateUrl: './profile-pitcure-popover.component.html',
  styleUrls: ['./profile-pitcure-popover.component.scss'],
})
export class ProfilePitcurePopoverComponent implements OnInit {

  constructor(private router: Router, private popoverCtrl: PopoverController, private authService: AuthenticationService) { }

  ngOnInit() {}

  async onDismiss() {
    try {
        await this.popoverCtrl.dismiss();
    } catch (e) {
        // click more than one time popover throws error, so ignore...
    }
  }

  logout() {
    this.authService.logout();
    this.onDismiss();
  }

  gotoPage(page) {
    this.router.navigate(['members', 'l', page], { replaceUrl: true });
    this.onDismiss();
  }
}
