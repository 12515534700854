import { Component, OnInit, NgZone } from '@angular/core';
import { UtilService } from '../../services/util.service';
import { UserService } from '../../services/user.service';
import { QuizService } from '../../services/quiz.service';
import { Router } from '@angular/router';
import { Platform, AlertController, ModalController } from '@ionic/angular';
import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';
import { StartQuiz } from '../start-quiz/start-quiz.component';
import { SocketService } from '../../services/socket.service';


function getWindow (): any {
  return window;
}

@Component({
  selector: 'app-takequiz',
  templateUrl: './takequiz.component.html',
  styleUrls: ['./takequiz.component.scss'],
})
export class TakequizComponent implements OnInit {
  takequiz_code: boolean = false;
  code: string = '';
  scan_code: string = '';
  attend_data: string = '';
  isScanning : boolean = false;
  window :any;
  devices : any = [];
  foundQuizzes : any = [];
  quizzesTaking: any = [];

  constructor(
  	private utill: UtilService,
  	private userService: UserService,
  	private quizService: QuizService,
  	private router: Router,
  	private barcodeScanner: BarcodeScanner,
  	private modalController: ModalController,
  	private platform: Platform,
  	private zone: NgZone,
    private socket: SocketService
  ) { }

  ngOnInit() {
  	this.window = getWindow();
    this.utill.getStorage(this.utill.tempSectionCode).then(val=>{
      if(val){
        this.code = this.utill.parseJSON(val);
        this.utill.removeStorage(this.utill.tempSectionCode);
      }
    })

    this.loadAnsweringQuiz()
  	// if(this.utill.sessionStorage.get(this.utill.tempSectionCode)){
  	// 	this.code = this.utill.sessionStorage.get(this.utill.tempSectionCode);
  	// 	this.utill.sessionStorage.remove(this.utill.tempSectionCode);
  	// }

    this.socket.quizStopped().subscribe((data:any)=>{
      if(data && data.section_id){
        this.quizzesTaking.forEach(quiz => {
          if(quiz.section._id == data.section_id){
            this.loadAnsweringQuiz();
            this.utill.showInfo("Quiz stopped by creator!");
          }
        });
      }
    });

    this.socket.runningQuizUpdate().subscribe((data:any) => {
      if(data && data.section_id){
        this.loadAnsweringQuiz();
      }
    })
  }

  stopBeaconScan(){
    this.isScanning = false;
    this.window.evothings.eddystone.stopScan(()=>{
      console.log("Scan Stopped!");
    })
  }

  startScanningForBeacons(){
  	if(this.isScanning){
  		this.stopBeaconScan();
  		return;
  	}

  	var that = this;
    this.platform.ready().then(() => {
      if (this.platform.is('cordova')) {
        setTimeout(()=>{
          this.window.evothings.eddystone.stopScan(()=>{
            console.log("Scan stopped!");
          })
        },10000);
        this.isScanning = true;
        this.window.evothings.eddystone.startScan((device) => {
        	if(device.hasOwnProperty('bid') && !this.devices.hasOwnProperty(device.address)){
              this.devices[device.address] = device;
              this.getActiveQuiz(device);
            }
        },
        error =>console.error('error: ', error))
      }
    });
  }

  getActiveQuiz(device){
  	var that = this;
    var data = {
        beacon_id : this.utill.uint8ArrayToString(device.bid),
        name : device.name,
        url : device.url
    };

    this.quizService.searchQuizzes(data).subscribe((resp:any)=>{
        if(resp.success){
            if(resp.quizzes){
                resp.quizzes.forEach((quiz:any) =>{
                   	this.foundQuizzes.push(quiz);
                   	this.zone.run(() => {console.log(that.devices)});
                })
            }
        }
    })
  }

  int8ArrayToString(uint8array){
    return this.utill.uint8ArrayToString(uint8array)
  }

  // assignBeacon(device){
  // 	var data = {
  //       beacon_id : this.utill.uint8ArrayToString(device.bid),
  //       name : device.name,
  //       url : device.url
  //   };
  //   this.userService.updateBeacon(data).subscribe((resp:any) =>{
  //       this.utill.showMessage(resp);
  //       if(resp.success){
  //           this.user.beacon = resp.beacon;
  //           this.stopBeaconScan();
  //       }
  //   })
  // }


  scanCode(type){
    this.barcodeScanner.scan().then(barcodeData => {
      if(this.utill.isObjectEmpty(barcodeData) || barcodeData.cancelled || this.utill.isObjectEmpty(barcodeData.text)){
	      this.utill.showError("Sorry no code found!");
      } else {
        var query = {'section_code': barcodeData.text.toUpperCase()};
        this.scan_code = barcodeData.text.toUpperCase()
        
        this.quizService.can_take_quiz(barcodeData.text.toUpperCase()).subscribe((resp:any)=>{
          // this.utill.sessionStorage.add(this.utill.tempSectionCode,this.code);
          this.utill.setStorage(this.utill.tempSectionCode,this.scan_code);
          if(!resp.success && !this.utill.isObjectEmpty(resp.terms)){
            this.router.navigateByUrl('/members/l/subscribe/'+resp.terms[0].code);
          } else if(resp.success) {
            this.takeQuiz(type)
            // this.openModalWithData(resp.quiz_data,resp.student_quiz,resp.studentQuizMeta);
              // $state.go('startQuiz', {quiz: resp.quiz, section: resp.section, student_quiz_meta: resp.studentQuizMeta});
          } else {
              this.utill.showMessage( resp);
          }
        })
      }
      // if(barcodeData && barcodeData.text)
      //  	this.code = barcodeData.text
    }).catch((e: any) => console.log('Error is', e));
  }

  loadAnsweringQuiz(){
    //** @NOTE */ 
    //Before this implementation, the user could take N quizzes at the same time. After a while, the application will return only one quiz here.
    this.quizService.takingQuiz().subscribe((resp: any) => {
      if(resp.answering_sections && resp.answering_sections.length > 0){
        this.quizzesTaking = resp.answering_sections;
      } else {
        this.quizzesTaking = [];
      }
    });
  }

  continueQuiz(code){
    this.code = code;
    this.attendQuiz(0);
  }

  attendQuiz(type){
  	this.utill.showLoadingHandler();
	  if (this.utill.isObjectEmpty(this.code)) {
      this.utill.showError("Section Code cannot be empty");
      return;
    }

    // Before this implementation, the user could take N quizzes at the same time. After a while, the application will return only one quiz here.
    if(this.quizzesTaking.length > 0){
      let sections = [];
      this.quizzesTaking.forEach(quiz => {
        sections.push(quiz.section.code)
      });
      if(!sections.includes(this.code)){
        this.utill.showError("You have unfinished quiz. Please, finish that quiz first.");
        return;
      }
    }

    this.quizService.getQuizByCode(this.code.toUpperCase())
      .subscribe((res:any)=>{
      	this.utill.hideLoadingHandler();
        if(!res.hasOwnProperty('quiz')){
            this.utill.showError("Quiz is not found with given code!");
        }else if (res.quiz.sections.time_tracking_enable) {

          var totalmins = res.quiz.time_limit / 60
          var hours = Math.floor(totalmins / 60)
          var mins = totalmins % 60
          var left_mins = mins < 10 ? '0' + mins : mins
          var MINUTE = 1
          var HOUR = MINUTE * 60
          var DAY = HOUR * 24
          var suffix

          if (totalmins >= MINUTE && totalmins < HOUR) {
            suffix = 'minute(s)'
          } else if (totalmins >= HOUR && totalmins < DAY) {
            suffix = 'hour(s)'
          } else {
            suffix = 'day(s)'
          }

          var present = this.utill.confirmAlert(
            'Info', 
            '', 
            'This quiz will end in ' + hours + ':' + left_mins + ' ' + suffix + ' from the time you start the quiz',
            [{
              'text':'Cancel',
              handler:()=>{

              }
            },{
              'text':'Continue',
              handler:()=>{
                this.takeQuiz(type)
              }
            }]
         );

          // present.then(function(res) {
            
          // })
        } else {
           this.takeQuiz(type)
        }
      })
  }

  takeQuiz(type){
  	this.utill.showLoadingHandler();
    var code = this.code;
    if(this.scan_code)
      code = this.scan_code
    var query = { 'section_code': code.toUpperCase() }

	  if (!this.utill.isObjectEmpty(type))
	    query['attend_type'] = type
	  this.quizService.takequiz(query)
	      .subscribe((resp:any)=>{
	      	// this.utill.sessionStorage.add(this.utill.tempSectionCode,this.code);
          this.utill.setStorage(this.utill.tempSectionCode,code);
	      	this.utill.hideLoadingHandler();
	          if (!resp.success) {
	              this.utill.showMessage(resp);
	              if (!this.utill.isObjectEmpty(resp.terms)){
	              	this.router.navigateByUrl('/members/l/subscribe/'+resp.terms[0].code);
	              	return true;
	              	// this.router.navigate(['members','l','subscribe'], { state: { termCode: resp.terms[0].code } });
	                  // $state.go('subscribeTerm', {termCode: resp.terms[0].code});
	              }
	          }

	          if (resp.success) {
              // this.utill.sessionStorage.add(this.utill.sessionTempQuiz, resp.section_code);
              this.utill.setStorage(this.utill.tempSectionCode,code);
	            if (!resp.student_quiz.length) {
	                this.utill.showError('There are no more question to answer');
	            } else {
	                // AnswerService.history.addQuiz(resp.quiz_data);
	                // $ionicHistory.nextViewOptions({
	                //     disableBack: true
	                // });
                  this.openModalWithData(resp.quiz_data,resp.student_quiz,resp.studentQuizMeta);
	                // $state.go("answer_questions", {
	                //     'questions': resp.student_quiz,
	                //     'quiz_data': resp.quiz_data,
	                //     'student_quiz_meta': resp.studentQuizMeta,
	                //     'number_of_questions_left': resp.student_quiz.length
	                // });
	            }
	          }
	      })
  }

  openModalWithData(quiz,section,student_quiz_meta) {
    this.router.navigate(['members','l','answer-questions'],{state:{
        'quiz_data': quiz,
        'questions': section,
        student_quiz_meta
    }});
    // const modal = await this.modalController.create({
    //   component: StartQuiz,
    //   componentProps: {
    //   	'quiz_data': quiz,
    //   	'questions': section,
    //   	student_quiz_meta
    //   }
    // });

    // modal.onWillDismiss().then(dataReturned => {
    // });

    // return await modal.present().then(_ => {
    // });
  }
}
