import { Component, ViewChild, OnInit, Input, ElementRef } from '@angular/core';
import { UtilService } from './../../../services/util.service';
import { TermService } from './../../../services/term.service';
import { ModalController } from '@ionic/angular';
import { HttpClient, HttpEvent, HttpErrorResponse, HttpEventType } from  '@angular/common/http';
import { map } from  'rxjs/operators';

@Component({
  selector: 'app-create-user-with-file',
  templateUrl: './create-user-with-file.component.html',
  styleUrls: ['./create-user-with-file.component.scss'],
})
export class CreateUserWithFileComponent implements OnInit {
  @Input() term: any;
  newUsers: any = {
  	file:'',
  	subscribed:false,
  	idAllowed:false
  };
  @ViewChild('filechooser',undefined) fileChooserElementRef: ElementRef;
  file: any;
  constructor(
  	private utill: UtilService,
  	private termService: TermService,
  	private modalCtrl: ModalController
  ) { }

  ngOnInit() {
  }

  uploadFile(event){
    this.file = (event.target as HTMLInputElement).files[0];
  }

  createUser(){
  	this.termService.createUserWithCsv(
  		this.file,this.term._id,this.newUsers.subscribed,this.newUsers.idAllowed
  	).subscribe((resp:any)=>{
  		this.utill.showMessage(resp);
  		if(resp.success){
  			this.modalCtrl.dismiss();
  		}
  	})
  }

}
