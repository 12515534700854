import { Component, OnInit, Input } from '@angular/core';
import { UtilService } from './../../../services/util.service';
import { TermService } from './../../../services/term.service';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.scss'],
})
export class CreateUserComponent implements OnInit {
  @Input() term: any;
  newUser: any = {
  	email:'',
  	subscribed:false,
	idAllowed:false,
	identification_id:''
  };
  constructor(
  	private utill: UtilService,
  	private termService: TermService,
	private modalCtrl: ModalController,
  ) { }

  ngOnInit() {}

  createUser(){
  	this.termService.createUser(this.term._id,this.newUser).subscribe((resp:any)=>{
  		this.utill.showMessage(resp);
  		if(resp.success){
  			this.modalCtrl.dismiss();
  		}
  	});
  }

}
