import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-loader-rating',
  templateUrl: './loader-rating.component.html',
  styleUrls: ['./loader-rating.component.scss'],
})
export class LoaderRatingComponent implements OnInit {

  @Input() rating;

  constructor() { 
  }

  ngOnInit() {
  }

  calculateRating(x){
  	this.rating = this.rating || 0;
  	this.rating = Math.ceil(this.rating);
  	return this.rating < x
  }

}
