import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { UtilService } from './util.service';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  constructor(
  	private _http: HttpClient,
  	private util: UtilService,
  	) { }

  loadAllPackages(){
    return this._http.get(this.util.url+'/api/packages');
  }

  loadPackages(id){
     return this._http.get(this.util.url+'/api/packages/'+id); 
  }

  createPackage(data){
    return this._http.post(this.util.url+'/api-v2/packages',data)
  }

  updatePackage(id,data){
    return this._http.put(this.util.url+'/api-v2/packages/'+id,data)
  }

  updateUserPackage(user_id,package_id){
    return this._http.put(this.util.url+'/api/users/'+user_id,{package_id});
  }

  getUsers(page,query){
    let url = this.util.url+'/api-v2/users/paginate/'+page;
    if(!this.util.isObjectEmpty(query))
      url += '/'+query;
  	return this._http.get(url);
  }

  loginAs(user_id){
  	return this._http.post(this.util.url+'/api/authenticate/login-as',{'login_as':user_id});
  }
}
