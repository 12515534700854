import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchKey'
})
export class SearchKeyPipe implements PipeTransform {
  //searchKey:instructor:'first_name'
  transform(items: any[], ...args): any[] {
    if(!items) return [];
    if(!args.length || !args[0]) return items;
    let searchTerm = args[0].toString().toLowerCase();
    args.shift();
    if(!args) return items;

    return items.filter( it => {
    	return args.find(val=>{
    		return it[val] && it[val].toString().toLowerCase().includes(searchTerm);
    	});
    });
  }

}
