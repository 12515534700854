import { Platform } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Storage } from '@ionic/storage';
import { UtilService } from './util.service';
import { UserService } from './user.service';
import { AdminService } from './admin.service';
import { HttpClient, HttpParams } from '@angular/common/http';

export interface User{
	first_name: string;
	last_name: string;
  identification_ids: string;
	email: string;
	roles: string[];
  role: string;
  name: string;
  _id: string;
  avatar: any;
}


@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  
  public authenticationState = new BehaviorSubject(false);
  public userCheckingState = new BehaviorSubject(true);
  public currentUser: BehaviorSubject<User> = new BehaviorSubject(null);

  constructor(
    private _http: HttpClient,
    private adminService: AdminService,
    private userService: UserService,
  	private storage: Storage, 
  	private plt: Platform, 
  	private util: UtilService
  ) { 
  	// this.plt.ready().then(()=>{
  	// 	this.checkToken();
  	// })
  }
  
  login(email, password){
    return this.userService.loginUser(email,password).subscribe((resp:any) => {
      if(resp.success){
        this.util.setStorage(this.util.authKey, resp.token)
        return this.getLoggedInUser();
      }else{
        this.util.showMessage(resp);
      }
    })
  }

  loginAs(user_id){
    this.adminService.loginAs(user_id).subscribe((resp:any) =>{
      if(resp.success){
        this.util.setStorage(this.util.authKey, resp.token)
        return this.getLoggedInUser();
      }else{
        this.util.showMessage(resp);
      }
    });
  }
  setUserWithToken(token){
    var that = this;
    that.util.hideLoadingHandler();
    that.util.showLoadingHandler('Loading User!');
    that.userService.getUserWithToken(token).subscribe((resp:any) =>{
        that.util.hideLoadingHandler();
        that.currentUser.next(null);
        that.currentUser.next(resp.user);
        that.authenticationState.next(true);
      })
  }
  getLoggedInUser(){
    var that = this;
    that.util.hideLoadingHandler();
  	that.util.showLoadingHandler('').then(()=>{
      that.userService.getUser().subscribe((resp:any) =>{
        that.util.hideLoadingHandler();
        that.currentUser.next(null);
        that.currentUser.next(resp.user);
        that.authenticationState.next(true);
      })
	  })
  }

  getUserSubject(){
  	return this.currentUser.asObservable();
  }

  logout(){
  	this.util.removeStorage(this.util.authKey)
    if(this.authenticationState.value === true)
      this.authenticationState.next(false);
    return this.currentUser.next(null);
  }

  hasRoles(roles: string[]):boolean{
    var can_view = false;
  	for(const oneRole of roles)
  		if(this.currentUser.value){
        if(this.currentUser.value.hasOwnProperty('role') && this.currentUser.value.role == oneRole){
          can_view = true;
        }else if(this.currentUser.value.roles && this.currentUser.value.roles.includes(oneRole)){
          can_view = true;
        }
      }
  	return can_view;
  }

  isAuthenticated(){
  	return this.authenticationState.value;
  }

  checkToken(){
  	// this.util.getStorage(this.util.authKey)
    this.userCheckingState.next(false);
    return this.getLoggedInUser();
  }

  getToken(){
    // return this.util.getStorage(this.util.authKey);
    return this.util.getStorage(this.util.authKey).then((val)=>{
      return this.util.parseJSON(val);
    });
  }

  checkDomain(email){
    return this.userService.checkDomain(email)
  }

  authUserCheck(accessCode){
    return this.userService.authUserCheck(accessCode);
  }
}
