import { Component, OnInit } from '@angular/core';
import { UtilService } from './../../../services/util.service';
import { UserService } from './../../../services/user.service';
import { NavController, ModalController } from '@ionic/angular';

@Component({
  selector: 'app-update-password',
  templateUrl: './update-password.component.html',
  styleUrls: ['./update-password.component.scss'],
})

export class UpdatePasswordComponent implements OnInit {
	passwordChange = {
		old_password: '',
		password: '',
		confirm_password: ''
	};
	constructor(
		private userService: UserService,
		private util: UtilService,
		private nav: NavController,
		private modalCtrl: ModalController
	)
	{ }

	ngOnInit() {
		this.passwordChange.old_password = "";
		this.passwordChange.password = "";
		this.passwordChange.confirm_password = "";
	}

	closeModal() {
		this.modalCtrl.dismiss();
	}

	updatePassword(){
		try {
			if (this.util.isObjectEmpty(this.passwordChange.old_password)) {
				throw new Error('Please enter your old password.');
			}

			if (this.util.isObjectEmpty(this.passwordChange.password)) {
				throw new Error("Please enter your new password.");
			}

	    	if (this.util.isObjectEmpty(this.passwordChange.confirm_password)) {
				throw new Error("Please confirm your new password.");
			}

	    	if (this.passwordChange.password != this.passwordChange.confirm_password) {
				throw new Error("The passwords do not match.");
			}

	    	if (this.passwordChange.password === this.passwordChange.old_password){
				throw new Error("Nothing to update.");
			}

	    	var that = this;
	    	this.util.showLoadingHandler('').then(()=>{
		    this.userService.updatePassword(this.passwordChange)
		        .subscribe((resp: any) =>{
		        	that.util.hideLoadingHandler();
		        	this.util.showMessage(resp);
		            if(resp.success) {
		                this.passwordChange.old_password = "";
		                this.passwordChange.password = "";
		                this.passwordChange.confirm_password = "";
		                this.modalCtrl.dismiss();
		            }
		        })
			})
	} catch (err) {
		this.util.showError(err.message);
	}
  }

}
