import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'calculatePercentage'
})
export class CalculatePercentagePipe implements PipeTransform {

  transform(value: any, args: number[]): any {
  	let total = 0;

  	if(value && args)
  		args.forEach(a => {
	  		total += a;
	  	});
    return total == 0 ? total : Math.round(value / total * 100);
  }

}
