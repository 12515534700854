import { Component, ViewChild, OnInit, Input, ElementRef } from '@angular/core';
import { UtilService } from './../../../services/util.service';
import { TermService } from './../../../services/term.service';
import { HttpClient, HttpEvent, HttpErrorResponse, HttpEventType } from  '@angular/common/http';
import { map } from  'rxjs/operators';
import { NavParams, AlertController, PopoverController } from '@ionic/angular';
import { Router, RouterEvent } from '@angular/router';
import { SocketService } from './../../../services/socket.service';
import { NavController, ModalController, ActionSheetController, Events } from '@ionic/angular';
import * as _ from 'lodash';

@Component({
  selector: 'app-create-question-modal',
  templateUrl: './create-question-modal.component.html',
  styleUrls: ['./create-question-modal.component.scss'],
})
export class CreateQuestionModalComponent implements OnInit {
  @Input() question: any;
  @Input() quiz: any;
  @Input() action: any;
  // totalOptions=['',''];
  totalOptions=[];
  pageTitle='Add';
  isThereChanges = false;
  beforeHtml:any = null;
  beforeQuestionTitle:any = '';
  beforeOptions:any;
  beforeCorrectAnswer:any;

  constructor(
  	private utill: UtilService,
  	private router: Router, 
  	private popoverCtrl: PopoverController, 
  	private navParams: NavParams,
  	private util: UtilService,
  	private modalCtrl: ModalController,
  	private termService: TermService,
  	private alertController: AlertController,
  	private socket: SocketService
  ) { }

  ngOnInit() {
    this.question = this.navParams.get('question');
    if(!this.question)
    	this.question = {
    		html: true,
    		question_title: '',
    		options: ['',''],
        correct_answer:''
      }
      
    this.question.options.forEach(()=>{
      this.totalOptions.push('');
    })
    // this.totalOptions.push('');
    
    this.quiz = this.navParams.get('quiz');
    this.action = this.navParams.get('action');
 //    this.socket.termUpdate().subscribe(data=>{
 //      if(data && data.term_id && this.term._id == data.term_id){
 //        this.term[data.field.key] = data.field.value;
 //      }
  // });
    this.beforeHtml = this.question.html
    this.beforeQuestionTitle = this.question.question_title
    this.beforeOptions = this.question.options.slice(0)
    this.beforeCorrectAnswer = this.question.correct_answer
  }

  async closeModal(saveClicked?) {
    if(!saveClicked && (this.beforeHtml != this.question.html || 
      this.beforeQuestionTitle != this.question.question_title ||
      JSON.stringify(this.beforeOptions.join('').split(''))!=JSON.stringify(this.question.options.join('').split('')) ||
      this.beforeCorrectAnswer != this.question.correct_answer
    )){
      this.isThereChanges = true
    }

    if(this.isThereChanges){
      this.isThereChanges = false
      const alert = await this.alertController.create({
        header: 'Changes not saved!',
        message: 'Do you want to save the changes made?',
        buttons: [
          {
            text: 'Close',
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
              this.question.html = this.beforeHtml
              this.question.question_title = this.beforeQuestionTitle
              this.question.options = this.beforeOptions.slice(0)
              this.question.correct_answer = this.beforeCorrectAnswer
              this.modalCtrl.dismiss();
            }
          }, {
            text: 'Save the changes',
            handler: () => {
              this.saveQuestion()
            }
          }
        ]
      });
      await alert.present();
   
    } else {
      if(saveClicked)
        this.modalCtrl.dismiss({
          'dismissed':true,
          'question':this.question
        });
      else
        this.modalCtrl.dismiss();
      }
  }

  optionValueChange(){
    var noempty = true;
  	this.question.options.forEach(o=>{
      if(o==''){
        noempty = false
      }
    })
    if(noempty){
      this.question.options.push('')
      this.totalOptions.push('');
    }
  }

  removeOption(i){
    if(this.question.options.length > 2){
      this.question.options.splice(i,1);
      this.totalOptions.splice(i,1);
    }else{
      this.utill.showError("You must have at least 2 option!");
    }
  }

  saveQuestion(){
    if (this.utill.isObjectEmpty(this.question.html))
          this.question.html = false;

      if (!this.question.correct_answer) {
          this.utill.showError("Correct answer could not be empty");
          return;
      }

      if (!this.question.question_title) {
          this.utill.showError("Correct answer could not be empty");
          return;
      }

      if(!this.question.options.length || this.question.options.length < 2){
          this.utill.showError("Question should contain minimum 2 answer selection");
          return;
      }

      this.question.correct_answer = this.question.html ? this.utill.Sanitize.encodeJS(this.question.correct_answer) : this.question.correct_answer;
      this.question.question_title = this.question.html ? this.utill.Sanitize.encodeJS(this.question.question_title) : this.question.question_title;

      var emptyAns = [];

      for (var i = 0, length = this.question.options.length; i < length; i++) {
          this.question.options[i] = this.question.html ? this.utill.Sanitize.encodeJS(this.question.options[i]) : this.question.options[i];
          if (this.question.options[i] == "") {
              emptyAns.push(i)
          }
      }

      if (emptyAns)
          emptyAns.forEach(i=>{
            this.question.options.splice(i,1);
          })
      
      this.isThereChanges = false
      this.closeModal(true);

      // if ($scope.editModal)
      //     $scope.editModal.remove();

      // if ($scope.isCreateNew) {
      //     $scope.listData.push(this.question);
      // }
      // else {
      //     $scope.listData[this.question.index] = this.question;
      //     delete $scope.listData[this.question.index].correctIndex;
      //     delete $scope.listData[this.question.index].index;
      // }
      // delete this.question;
  }
}
