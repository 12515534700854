import { Component, OnInit, NgZone } from '@angular/core';
import { QuizService } from './../../services/quiz.service';
import { UtilService } from './../../services/util.service';
import { NavParams, AlertController, PopoverController } from '@ionic/angular';
import { Router, RouterEvent } from '@angular/router';
import { SocketService } from './../../services/socket.service';
import { UserService } from './../../services/user.service';
import { AuthenticationService } from './../../services/authentication.service';
import * as moment from 'moment';

interface ActiveQuizzes{
  active_section:{},
  attendance_data:{},
  create_on:Date,
  created_on:Date,
  creator:String,
  name:String,
  number_of_questions:Number,
  send_question_limit:Number,
  shared_width:[],
  status:Number,
  terms:[],
  time_limit:Number,
  _id:String
}


@Component({
  selector: 'app-quiz-dashboard-data',
  templateUrl: './quiz-dashboard-data.component.html',
  styleUrls: ['./quiz-dashboard-data.component.scss'],
})
export class QuizDashboardDataComponent implements OnInit {
  number_of_active = 0;
  number_of_quizzes = 0;
  attended_quizzes = 0;
  number_of_terms = 0;
  listData:any;
  activeQuizzes : ActiveQuizzes[] = [];
  user:any;
  student_last_quizzes: any;
  loadCompleted = false;

  constructor(
  	private quizService:QuizService,
  	private utill: UtilService,
    private alertController: AlertController,
    private router: Router,
    private socket: SocketService,
    private zone: NgZone,
    private userService:UserService,
    private authService:AuthenticationService
  ) { }

  ngOnInit() {
    this.authService.currentUser.subscribe(user => {
      this.user = user;
    });
    this.loadData();
    this.socket.runningQuizUpdate().subscribe((data:any)=>{
      if(data && data.section_id){
        this.sectionStats(data.section_id)
      } 
    });
  }

  sectionStats(section_id){
    this.quizService.sectionStats(section_id).subscribe((resp:any)=>{
      if(resp.success){
        var q_index = this.activeQuizzes.findIndex((aq:any)=>aq.active_section._id==resp.section_id);
        if(this.activeQuizzes[q_index]){
          this.activeQuizzes[q_index].attendance_data = resp.data;
        }
        this.zone.run(() => {});
      }
    })
  }

  loadData(){
    this.quizService.count().subscribe((res:any)=>{
        if (res && res.success) {
            this.number_of_active = res.data.active;
            this.number_of_quizzes = res.data.quiz;
            this.attended_quizzes = res.data.attended_quizzes;
            this.number_of_terms = res.data.terms;
        } else {
            this.utill.showMessage( res)
        }
    })

    this.loadLastQuizzesTook();

    this.quizService.activeQuizzes().subscribe((res:any)=>{
        if (res && res.success) {
            this.activeQuizzes = res.active_quizzes;
        } else {
            this.utill.showMessage( res)
        }
    })
  }

  loadLastQuizzesTook(){
    // this.userService.myQuizReport().subscribe((resp:any)=>{
    //   console.log('resp: ', resp)
    //   var student_report = []
    //   resp.student_report.forEach(term => {
    //     term.quizzes.map(quiz =>{
    //       quiz.sections.map(section =>{
    //         student_report.push({
    //           quiz_start_date: section.start,
    //           term_id: term._id,
    //           term_name: term.name,
    //           term_creator: term.creator.first_name + ' ' + term.creator.last_name,
    //           quiz_id: quiz._id,
    //           quiz_name: quiz.name,
    //           quiz_score: section.score,
    //           quiz_total: section.total,
    //         })
    //       })
    //     })
    //   });
    //   this.student_last_quizzes = student_report.sort((a, b) => moment(b.quiz_start_date).diff(moment(a.quiz_start_date), 'seconds'))
    //   this.student_last_quizzes = this.student_last_quizzes.slice(0, 5)
    //   this.loadCompleted = true
    // })
  }

  stopAlert:any;
  async stopQuiz(quiz,index) {
      var that = this;
      that.stopAlert = await this.alertController.create({
            'header': 'Stop Quiz',
            'subHeader': '',
            'message': 'You are about to stop the quiz!',
            'buttons':[{
                'text':'Cancel',
                'role':'cancel',
                handler:data=>{}
                  },{
                    'text': 'Stop Test',
                    handler:data=>{
                      that.__stopQuiz(quiz,index);
                      that.stopAlert.dismiss();
                      that.router.navigateByUrl('/members/l/quiz/'+quiz._id);
                    }
                  }]
            });
      await that.stopAlert.present();
    }

  __stopQuiz(quiz,index){

    this.quizService.stopQuiz(quiz.active_section.code).subscribe((resp:any)=>{
      if(resp.success){
        this.activeQuizzes.splice(index,1);
      }
    })
  }

  takeTheMark(percentage){
    return this.utill.convertToMark(percentage)
  }

}
