import { Component, ViewChild, OnInit, Input, ElementRef } from '@angular/core';
import { UtilService } from './../../../services/util.service';
import { TermService } from './../../../services/term.service';
import { HttpClient, HttpEvent, HttpErrorResponse, HttpEventType } from  '@angular/common/http';
import { map } from  'rxjs/operators';
import { NavParams, AlertController, PopoverController } from '@ionic/angular';
import { Router, RouterEvent } from '@angular/router';
import { SocketService } from './../../../services/socket.service';
import { NavController, ModalController, ActionSheetController, Events } from '@ionic/angular';
import { TermHelpComponent } from '../../term-help/term-help.component';

@Component({
  selector: 'app-section-participants',
  templateUrl: './section-participants.component.html',
  styleUrls: ['./section-participants.component.scss'],
})
export class SectionParticipantsComponent implements OnInit {
  @Input() selectedParticipants = [];	
  @Input() quiz: any;
  filteredParticipants = [];
  participants = [];
  termParticipants = [];
  selectAll = false;
  noselectedProvided = false;
  @ViewChild('fileInput',undefined) fileInput:ElementRef;

  constructor(
  	private utill: UtilService,
  	private router: Router, 
  	private popoverCtrl: PopoverController, 
  	private navParams: NavParams,
  	private modalCtrl: ModalController,
  	private termService: TermService,
  	private alertController: AlertController,
	private socket: SocketService,
  ) { }

  ngOnInit() {
	this.quiz = this.navParams.get('quiz');
	this.termParticipants = this.quiz.terms[0].allowed_ids;
    this.selectedParticipants = this.navParams.get('selectedParticipants').map(String);
  }

  ionViewWillEnter(){
  	this._arrangeParticipantIds();
  }

  _arrangeParticipantIds(){
  	this.noselectedProvided = !this.selectedParticipants.length;
  	if(this.quiz.terms[0].allowed_ids){
		this.participants = [];
		this.quiz.terms[0].allowed_ids.map(String).forEach(id=>{
			let temp_sub: any;
			temp_sub = this.quiz.terms[0].subscribers.find(sub=>{
				return sub.identification_id == id;
			})
			if(!temp_sub)
				temp_sub = {};

			temp_sub['allowed_id'] = id;
			if(this.noselectedProvided){
				temp_sub['isChecked'] = true;
				this.selectedParticipants.push(id.toString());
			}else
				temp_sub['isChecked'] = this.selectedParticipants.indexOf(id) != -1;
			this.participants.push(temp_sub);
		})
		this.filteredParticipants = this.participants;
	}else{
		this.filteredParticipants = this.quiz.terms[0].subscribers;
	}
  }

  filterParticipants($event){
  	this.filteredParticipants = this.participants.filter((participant) => {
      return (participant.user && participant.user.first_name && participant.user.first_name.toLowerCase().indexOf($event.target.value.toLowerCase()) > -1) || 
      		 (participant.user && participant.user.last_name && participant.user.last_name.toLowerCase().indexOf($event.target.value.toLowerCase()) > -1) || 
      		 (participant.user && participant.user.email && participant.user.email.toLowerCase().indexOf($event.target.value.toLowerCase()) > -1) || 
      		 participant.allowed_id.toLowerCase().indexOf($event.target.value.toLowerCase()) > -1;
    });
  }

  selectAllParticipants(){
  	this.selectedParticipants = [];
  	this.participants.forEach(p=>{
  		p['isChecked'] = this.selectAll;
  		if(this.selectAll)
  			this.selectedParticipants.push(p.allowed_id || p.identification_id);
  	});
  }

  closeModal(saveClicked?) {
    if(saveClicked)
      this.modalCtrl.dismiss({
        'dismissed':true,
        'selectedParticipants':this.selectedParticipants
      });
    else
      this.modalCtrl.dismiss();
  }

  updateSelected(participant){
  	//since click happens before change assume status is reverse
  	if(!participant.isChecked)
  		this.selectedParticipants.push(participant.allowed_id);
  	else
  		this.selectedParticipants.splice(this.selectedParticipants.indexOf(participant.allowed_id),1);
  }

  selectionAlert:any;

  async showActions() {
    var that = this;
  	that.selectionAlert = await this.alertController.create({
		      'header': 'Add Participants',
		      'subHeader': '',
		      'message': 'You can add the participants through the list selected or uploading a CSV file with the participanyts IDs',
		      'buttons':[{
		        	'text':'Cancel',
		        	'role':'cancel',
		        	handler:data=>{}
		            },{
		            	'text': 'CSV',
		            	handler:data=>{
		            		that.selectionAlert.dismiss();
		            		that.fileInput.nativeElement.click();
		            	}
		            },{
		            	'text': 'Add Selected',
		            	handler: data=>{
		            		that.selectionAlert.dismiss();
		            		that.closeModal(true);
		            	}
		            }]
			    });
  	await that.selectionAlert.present();
  }

  addSelected(){
	this.closeModal(true);
  }

  addCsv(){
	this.fileInput.nativeElement.click();
  }

  addParticipants(event){
  	var that = this;
  	var file = (event.target as HTMLInputElement).files[0];
  	var reader = new FileReader();
  	var participant_ids = [];
  	if (!this.utill.isCSV(file.name)) {
        this.utill.showError("Currently we only accept .csv file");
        return;
    }

    reader.onloadend = function (evt) {
		var ids = that.utill.CSV2JSON(reader.result);
        if(ids){
        	that.selectedParticipants = [];
	        ids.forEach(row=>{
	        	var stu_id = row.id || row.identification_id;
				
	            if(!that.utill.isObjectEmpty(stu_id)){
					that.termParticipants.filter(function(item){ 
						if(stu_id.indexOf(item) > -1){
							that.selectedParticipants.push(stu_id);
						}
						return 
					});
					if(that.selectedParticipants.length == 0){
						that.utill.showError('This CSV does not contains this Term participants ID')
					}
				}
	        })
	    }
        setTimeout(function(){
            event.target.value =''; // change file name for next upload
            that.closeModal(true);
		},400);
    };
    reader.readAsText(file);//readAsBinaryString
  }

  async openHelpPopover(){
	const modal = await this.modalCtrl.create({
	component: TermHelpComponent,
	componentProps: {'term':null}
  });
  return await modal.present();
}
}
