import { Component, OnInit, Input } from '@angular/core';
import { AnonymousCommentComponent } from '../anonymous-comment.component';
import { ModalController } from '@ionic/angular';
import { StudentService } from '../../../services/student.service';

@Component({
  selector: 'app-anonymous-comment-link',
  templateUrl: './anonymous-comment-link.component.html',
  styleUrls: ['./anonymous-comment-link.component.scss'],
})
export class AnonymousCommentLinkComponent implements OnInit {
  // classId = 'any_id';
  // class: any;
  @Input() classId;
  @Input() instructorId;
  modalBackMessage: string;

  constructor(
    private modalController: ModalController,
    private studentService: StudentService
  ) { }

  ngOnInit() {}

  // async openModal() { // temp
  //   const modal = await this.modalController.create({
  //     component: AnonymousCommentComponent
  //   });
  //   return await modal.present();
  // }

  async openModalWithData() {
    const modal = await this.modalController.create({
      component: AnonymousCommentComponent,
      componentProps: {
        classId: this.classId,
        instructorId: this.instructorId,
      }
    });

    modal.onWillDismiss().then(dataReturned => {
      // this.modalBackMessage = dataReturned.data;
    });

    return await modal.present().then(_ => {
      // console.log('Class sending: ');
      // console.log(this.class);
    });
  }

}
