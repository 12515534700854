import { Component, ViewChild, OnInit, Input, ElementRef } from '@angular/core';
import { UtilService } from './../../../services/util.service';
import { TermService } from './../../../services/term.service';
import { QuizService } from './../../../services/quiz.service';
import { HttpClient, HttpEvent, HttpErrorResponse, HttpEventType } from  '@angular/common/http';
import { map } from  'rxjs/operators';
import { NavParams, AlertController, PopoverController } from '@ionic/angular';
import { Router, RouterEvent } from '@angular/router';
import { SocketService } from './../../../services/socket.service';
import { NavController, ModalController, ActionSheetController, Events } from '@ionic/angular';
import { CreateQuestionModalComponent } from './../create-question-modal/create-question-modal.component';
import { ChartType, ChartOptions } from 'chart.js';
import { MultiDataSet, Label, Color } from 'ng2-charts';

@Component({
  selector: 'app-sections-report',
  templateUrl: './sections-report.component.html',
  styleUrls: ['./sections-report.component.scss'],
})
export class SectionsReportComponent implements OnInit {
  @Input() quiz: any;
  sections: any = [];
  graphData:any = [0,0,0,0];

  doughnutChartType: ChartType = 'pie';

  // Circle
  chartOption: ChartOptions = {
    circumference: 2 * Math.PI,
    rotation: -Math.PI / 2,
    legend: {
      position: 'right',
    },
  };

  // Half Circle
  // chartOption: ChartOptions = {
  //   circumference: Math.PI,
  //   rotation: -Math.PI
  // };

  chartColors: Color[] = [{ 
    backgroundColor:['#f75f57','#f7aa57','#57a4f7','#57f78a'],
    // borderColor:['#1AB394','#989898'],
    pointHoverBackgroundColor: ['#f54b42','#f79e3e','#3e97f7','#40ed77'],
    // pointHoverBorderColor: ['#16a286','#7b7b7b'],
  }]


  doughnutChartLabels: Label[] = ["Below 50","Between 50 and 69","Between 70 and 85", "Above 85"];

  constructor(
  	private utill: UtilService,
  	private router: Router, 
  	private popoverCtrl: PopoverController, 
  	private navParams: NavParams,
  	private util: UtilService,
  	private modalCtrl: ModalController,
  	private termService: TermService,
  	private alertController: AlertController,
  	private socket: SocketService,
    private quizService: QuizService
  ) { }

  ngOnInit() {
    this.quiz = this.navParams.get('quiz');
    this.initSections();
    this.socket.runningQuizUpdate().subscribe((data:any)=>{
      if(data && data.section_id){
        var running_section = this.quiz.sections.find(s=>s._id==data.section_id)
        this.quizService.sectionStats(running_section._id).subscribe((resp:any)=>{
        	if(resp.success){
        		running_section['data'] = resp.data;
		        running_section['graphData'] = [
		        	resp.data.less_50,
				      resp.data.between_50_69,
				      resp.data.between_70_85,
				      resp.data.above_85
		        ];
        	}
        });
      }  
    });
  }

  initSections(){
    if(this.quiz.sections){
    	this.util.showLoadingHandler("Loading stats!");
    	this.quiz.sections.forEach(s=>{
    		this.quizService.sectionStats(s._id).subscribe((resp:any)=>{
		      if(resp.success){
		      	var sec = this.quiz.sections.find(se=>se._id == resp.section_id);
		        sec['expand'] = false;
		        sec['data'] = resp.data;
		        sec['graphData'] = [
		        	resp.data.less_50,
				      resp.data.between_50_69,
				      resp.data.between_70_85,
				      resp.data.above_85
            ]
            sec['average'] = resp.data.average;
		        this.sections.push(sec);
		        this.sections.sort((a, b) => b.start.localeCompare(a.start));
		        if(this.sections.length == this.quiz.sections.length)
    				this.util.hideLoadingHandler();
		      }
		    })
    	})
    }
  }

  sendSectionReport(section){
  	this.util.showLoadingHandler("Generating Report!");
    this.util.getStorage(this.util.authKey).then(token=>{
      token = token.replace(/\"/g,"");
      var url = this.util.url+'/api-v2/generate_report/download/section='+section.code+'&term='+this.quiz.terms[0]._id+'?token='+token;
      this.util.hideLoadingHandler();
      window.open(url,'_blank');
    })
   //  window.open(url, "_blank");
  	// this.quizService.sectionReport(section.code,this.quiz.terms[0]._id).subscribe((resp:any)=>{
  	// 	this.util.hideLoadingHandler();
  	// 	this.util.showMessage(resp);
  	// })
  }

  
  closeModal() {
    this.modalCtrl.dismiss();
  }

 
}