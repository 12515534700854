import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationComponent } from './notification/notification.component';
import { LoaderRatingComponent } from './loader-rating/loader-rating.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
// import { AvatarModule } from 'ng2-avatar';
import { AvatarModule } from 'ngx-avatar';
import { PipesModule } from './../pipe/pipe.module';
import { ChartsModule } from 'ng2-charts';
import { RouterModule } from '@angular/router';
import { ProfilePitcurePopoverComponent } from './profile-pitcure-popover/profile-pitcure-popover.component';
import { StudentUpcomingClassesComponent } from './student-upcoming-classes/student-upcoming-classes.component';
import { AnonymousCommentLinkComponent } from './anonymous-comment/anonymous-comment-link/anonymous-comment-link.component';
import { SendNotificationComponent } from './instructor/send-notification/send-notification.component';
import { SharedDirectivesModule } from './../directives/shared-directives.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { BackButtonComponent } from './back-button/back-button.component';
import { TimeAgoPipe } from 'time-ago-pipe';


import { TakequizComponent } from './takequiz/takequiz.component';
import { QuizDashboardDataComponent } from './quiz-dashboard-data/quiz-dashboard-data.component';
import { StartQuiz } from './start-quiz/start-quiz.component';
import { ShareQuizComponent } from './share-quiz/share-quiz.component';


import { TermPopoverComponent } from './term-popover/term-popover.component';
import { TermHelpComponent } from './term-help/term-help.component';
import { TermParticipantsComponent } from './term-participants/term-participants.component';
import { TermParticipantsModalComponent } from './term-participants-modal/term-participants-modal.component';
import { ShareTermComponent } from './share-term/share-term.component';
import { TermReportComponent } from './term-report/term-report.component';

import { CreateUserComponent } from './createUser/create-user/create-user.component';
import { CreateUserModalComponent } from './createUser/create-user-modal/create-user-modal.component';
import { CreateUserWithFileComponent } from './createUser/create-user-with-file/create-user-with-file.component';

import { QuizPopoverComponent } from './quiz-popover/quiz-popover.component';
import { QuizHelpComponent } from './quiz-help/quiz-help.component';
import { QuestionsModalComponent } from './quiz/questions-modal/questions-modal.component';
import { CreateQuestionModalComponent } from './quiz/create-question-modal/create-question-modal.component';

import { SectionsReportComponent } from './quiz/sections-report/sections-report.component';
import { SectionParticipantsComponent } from './quiz/section-participants/section-participants.component';
import { SectionBeaconsComponent } from './quiz/section-beacons/section-beacons.component';
import { EditQuizComponent } from './quiz/edit-quiz/edit-quiz.component';
import { PreviewQuizComponent } from './preview-quiz/preview-quiz.component';

import { SearchMemberQuizComponent } from './search-member-quiz/search-member-quiz.component';


import { UpdatePasswordComponent } from './profile/update-password/update-password.component';
import { UpdateNameComponent } from './profile/update-name/update-name.component';
import { UpdateImageComponent } from './profile/update-image/update-image.component';
import { ScanBluetoothComponent } from './profile/scan-bluetooth/scan-bluetooth.component';

import { DownloadAppComponent } from './download-app/download-app.component';

@NgModule({
  declarations: [
  	NotificationComponent,
    LoaderRatingComponent, 
    ProfilePitcurePopoverComponent,
    AnonymousCommentLinkComponent,
    SendNotificationComponent,
    StudentUpcomingClassesComponent,
    BackButtonComponent,
    TimeAgoPipe,
    TakequizComponent,
    QuizDashboardDataComponent,
    StartQuiz,
    TermPopoverComponent,
    QuizPopoverComponent,
    TermHelpComponent,
    TermParticipantsComponent,
    TermParticipantsModalComponent,
    DownloadAppComponent,
    TermReportComponent,
    CreateUserComponent,
    CreateUserModalComponent,
    CreateUserWithFileComponent,
    QuizHelpComponent,
    QuestionsModalComponent,
    SectionsReportComponent,
    CreateQuestionModalComponent,
    ShareTermComponent,
    SectionParticipantsComponent,
    ShareQuizComponent,
    SectionBeaconsComponent,
    SearchMemberQuizComponent,
    UpdatePasswordComponent,
    UpdateNameComponent,
    UpdateImageComponent,
    ScanBluetoothComponent,
    EditQuizComponent,
    PreviewQuizComponent
  ],
  imports: [
    RouterModule,
  	AvatarModule,
    CommonModule,
    NgxChartsModule,
    SharedDirectivesModule,
    IonicModule,
    FormsModule,
    PipesModule,
    ChartsModule,
    FontAwesomeModule,
  ],
  exports: [
  	NotificationComponent,
    LoaderRatingComponent,
    ProfilePitcurePopoverComponent,
    AnonymousCommentLinkComponent,
    SendNotificationComponent,
    StudentUpcomingClassesComponent,
    BackButtonComponent,
    TakequizComponent,
    QuizDashboardDataComponent,
    StartQuiz,
    TermPopoverComponent,
    TermHelpComponent,
    TermParticipantsComponent,
    TermParticipantsModalComponent,
    TermReportComponent,
    DownloadAppComponent,
    CreateUserComponent,
    CreateUserModalComponent,
    CreateUserWithFileComponent,
    QuizHelpComponent,
    QuestionsModalComponent,
    CreateQuestionModalComponent,
    QuizPopoverComponent,
    SectionsReportComponent,
    ShareTermComponent,
    SectionParticipantsComponent,
    ShareQuizComponent,
    SectionBeaconsComponent,
    SearchMemberQuizComponent,
    UpdatePasswordComponent,
    UpdateNameComponent,
    UpdateImageComponent,
    ScanBluetoothComponent,
    EditQuizComponent,
    PreviewQuizComponent
  ],
  entryComponents: [
    ProfilePitcurePopoverComponent,
    TermPopoverComponent,
    TermHelpComponent,
    QuizPopoverComponent,
    QuizHelpComponent,
    ShareTermComponent,
    SectionsReportComponent,
    QuestionsModalComponent,
    DownloadAppComponent,
    CreateQuestionModalComponent,
    TermParticipantsComponent,
    TermParticipantsModalComponent,
    TermReportComponent,
    CreateUserModalComponent,
    SendNotificationComponent,
    NotificationComponent,
    StartQuiz,
    SectionParticipantsComponent,
    ShareQuizComponent,
    SectionBeaconsComponent,
    SearchMemberQuizComponent,
    UpdatePasswordComponent,
    UpdateNameComponent,
    UpdateImageComponent,
    ScanBluetoothComponent,
    EditQuizComponent,
    PreviewQuizComponent
  ]
})
export class SharedAppComponents { }
