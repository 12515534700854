import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { UtilService } from './../../services/util.service';
import { TermService } from './../../services/term.service';
import { saveAs } from 'file-saver';
import { AuthenticationService } from './../../services/authentication.service';
import * as moment from 'moment';

@Component({
  selector: 'app-term-report',
  templateUrl: './term-report.component.html',
  styleUrls: ['./term-report.component.scss'],
})
export class TermReportComponent implements OnInit {
  @Input() term: any;
  quizzes: any = [];
  quiz_weight: any = '';
  ids: any = [];
  number_of_best_quizzes: any = '';
  participants_type: any = {
    type: 'all_participants'
  };
  @ViewChild('filechooser',undefined) fileChooserElementRef: ElementRef;
  file: any;

  constructor(
    private modalCtrl: ModalController,
    private util: UtilService,
    private termService: TermService,
    private authService: AuthenticationService
  ) { }

  ngOnInit() {
    this.ids = this.term.allowed_ids
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  selectQuiz(quiz_id, $event){
    if($event){
      this.quizzes.push(quiz_id)
    } else {
      const index = this.quizzes.indexOf(quiz_id);
      if (index > -1) {
        this.quizzes.splice(index, 1);
      }
    }
  }

  selectParticipantType(){
    this.ids = []
    if(this.participants_type.type == 'all_participants'){
      this.ids = this.term.allowed_ids
    } else if(this.participants_type.type == 'only_subscribers'){
      this.ids = [];
      this.term.subscribers.forEach((subscriber: any) => {
        this.ids.push(subscriber.identification_id)
      });
    }
  }

  deleteAll(){
    this.ids = []
  }

  uploadFile(event){
    this.file = (event.target as HTMLInputElement).files[0];
    
    this.termService.getParticipantsWithCsv(
  		this.file,this.term._id).subscribe((resp:any)=>{
  		this.util.showMessage(resp);
  		if(resp.success){
        this.ids = [];
        resp.participants.forEach(participant => {
          this.ids.push(participant.id)
        });
      }
  	})
  }

  generateReport(){
    if(!this.quiz_weight || this.quizzes.length == 0 || this.ids.length == 0 || !this.number_of_best_quizzes){
      this.util.showError('All the fields must be filled up')
    } else {
      this.util.showLoadingHandler("Generating Report!");
      this.termService.report(this.term._id, this.quiz_weight, this.quizzes, this.ids, this.number_of_best_quizzes).subscribe((resp:any)=>{
        var file_name = this.util.slugify(this.term.name) + '-' + moment().format('YYYY-MM-DD-HH-mm') +'.xlsx';
        saveAs(resp, file_name);
        this.util.hideLoadingHandler();
        this.closeModal();
      })
    }
  }



}
