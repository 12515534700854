import { Component, OnInit } from '@angular/core';
import { NotificationService, NotificationType } from './../../services/notification.service';
import { ModalController, Events } from '@ionic/angular';
import { SocketService } from './../../services/socket.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {
    // notifications: Notification[] = [];
    notifications: any = [];

	constructor(
        private notificationService: NotificationService,
        private modalController: ModalController,
        private socket: SocketService,
        public events: Events
    ) { }

	ngOnInit() {
		// this.notificationService.getNotification().subscribe((notification: Notification) => {
        //     if (!notification) {
        //         // clear notifications when an empty notification is received
        //         this.notifications = [];
        //         return;
        //     }

        //     // add notification to array
        //     this.notifications.push(notification);

        //     setTimeout(() => {
        //     	this.notifications.splice(this.notifications.indexOf(notification), 1);
        //     },(notification as any).delay)

        // });

        this.loadNotifications();
	}

	// removeAlert(notification: Notification) {
    //     this.notifications = this.notifications.filter(x => x !== notification);
    // }

    // cssClass(notification: Notification) {
    //     if (!notification) {
    //         return;
    //     }

    //     // return css class based on notification type
    //     switch ((notification as any).type) {
    //         case NotificationType.Success:
    //             return 'alert alert-success';
    //         case NotificationType.Error:
    //             return 'alert alert-danger';
    //         case NotificationType.Info:
    //             return 'alert alert-info';
    //         case NotificationType.Warning:
    //             return 'alert alert-warning';
    //     }
    // }

    async closeModal() {
        await this.modalController.dismiss();
    }

    loadNotifications() {
        this.notificationService.getNotifications().subscribe((resp: any) => {
            this.notifications = resp.notifications;
        });
    }

    deleteNotification(id) {
        this.notificationService.deleteNotification(id).subscribe(resp => {
            // console.log(resp);
            this.events.publish('notificationUpdated');
            this.loadNotifications();
        })
    }

}
