import { Component, ViewChild, OnInit, Input, ElementRef } from '@angular/core';
import { UtilService } from './../../services/util.service';
import { TermService } from './../../services/term.service';
import { HttpClient, HttpEvent, HttpErrorResponse, HttpEventType } from  '@angular/common/http';
import { map } from  'rxjs/operators';
import { NavParams, AlertController, PopoverController } from '@ionic/angular';
import { Router, RouterEvent } from '@angular/router';
import { SocketService } from './../../services/socket.service';
import { NavController, ModalController, ActionSheetController, Events } from '@ionic/angular';
import { QuizService } from './../../services/quiz.service';
import { UserService } from './../../services/user.service';
@Component({
  selector: 'app-search-member-quiz',
  templateUrl: './search-member-quiz.component.html',
  styleUrls: ['./search-member-quiz.component.scss'],
})
export class SearchMemberQuizComponent implements OnInit {
  @ViewChild('searchInput',undefined) searchInput:ElementRef;
  quiz_list: any;
  student_list: any;
  page = 1;
  query: any;
  constructor(
  	private utill: UtilService,
  	private router: Router, 
  	private popoverCtrl: PopoverController, 
  	private navParams: NavParams,
  	private util: UtilService,
  	private modalCtrl: ModalController,
  	private termService: TermService,
  	private alertController: AlertController,
  	private socket: SocketService,
  	private quizService: QuizService,
  	private userService: UserService
  ) { }

  ngOnInit() {
  }

  ionViewWillEnter(){

  }


  ionViewDidEnter(){
  	// this.searchInput.nativeElement.focus();
  	let searchbar: any = document.getElementsByClassName('searchbar-input')[0];
    searchbar.focus();//setAttribute("onFocus", "openSearchModal()");
  }

  search(){
  	this.quizService.query(this.query, this.page)
        .subscribe((data:any)=>{
            if (data && data.success && data.hasOwnProperty('quizzes')) {
                if (!this.quiz_list) {
                    this.quiz_list = []
                }
                if (this.page == 1) {
                    this.quiz_list = []
                }
                this.quiz_list = this.quiz_list.concat(data.quizzes);
                // callback(data.page != data.pages)
                // SessionStorage.add('dashboard_quiz_result', this.quiz_list)
            }
        })

    this.userService.query(this.query, this.page)
        .subscribe((data:any)=>{
            if (data && data.success && data.hasOwnProperty('students')) {
                if (!this.student_list) {
                    this.student_list = []
                }
                if (this.page == 1) {
                    this.student_list = []
                }
                this.student_list = this.student_list.concat(data.students);
                // callback(data.page != data.pages)
                // SessionStorage.add('dashboard_quiz_result', this.quiz_list)
            }
        })
  }

  closeModal() {
    //   this.router.navigateByUrl('/members/l/student-report/'+student_id);
      this.modalCtrl.dismiss();
  }

}
