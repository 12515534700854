import { Component, ViewChild, OnInit, Input, ElementRef } from '@angular/core';
import { UtilService } from './../../../services/util.service';
import { TermService } from './../../../services/term.service';
import { QuizService } from './../../../services/quiz.service';
import { HttpClient, HttpEvent, HttpErrorResponse, HttpEventType } from  '@angular/common/http';
import { map } from  'rxjs/operators';
import { NavParams, AlertController, PopoverController } from '@ionic/angular';
import { Router, RouterEvent } from '@angular/router';
import { SocketService } from './../../../services/socket.service';
import { NavController, ModalController, ActionSheetController, Events } from '@ionic/angular';
import { CreateQuestionModalComponent } from './../create-question-modal/create-question-modal.component';

@Component({
  selector: 'app-questions-modal',
  templateUrl: './questions-modal.component.html',
  styleUrls: ['./questions-modal.component.scss'],
})
export class QuestionsModalComponent implements OnInit {
  @Input() questions: any;
  @Input() quiz: any;
  editingQuestion:any;
  constructor(
  	private utill: UtilService,
  	private router: Router, 
  	private popoverCtrl: PopoverController, 
  	private navParams: NavParams,
  	private util: UtilService,
  	private modalCtrl: ModalController,
  	private termService: TermService,
  	private alertController: AlertController,
  	private socket: SocketService,
    private quizService: QuizService
  ) { }

  ngOnInit() {
    this.questions = this.navParams.get('questions') || [];
    this.quiz = this.navParams.get('quiz');

 //    this.socket.termUpdate().subscribe(data=>{
 //      if(data && data.term_id && this.term._id == data.term_id){
 //        this.term[data.field.key] = data.field.value;
 //      }
  // });
  }

  initQuestions(){
    if(this.questions)
      this.questions.map(q=>{
        q['expand'] = false;
      });
  }

  closeModal(keep_questions?) {
    if(keep_questions && keep_questions == true){}
    else
      this.questions = [];
    this.modalCtrl.dismiss({
      'dismissed':true,
      'questions':this.questions
    });
  }

  /*
  We can use same modal for editing
  */
  async addEditQuestion(index?){
    if(index>=0)
      this.editingQuestion = index;
    var modal = await this.modalCtrl.create({
      component: CreateQuestionModalComponent,
      componentProps: {'question':this.questions[index],'quiz':this.quiz}
    });

    await modal.present();

    var { data } = await modal.onWillDismiss();
    if(data && data.question){
      if(this.editingQuestion != null && this.editingQuestion>=0){
        this.questions[this.editingQuestion] = data.question;
        this.editingQuestion = null;
      }else
        this.questions.push(data.question);
    }

  }

   deleteQuestion(index) {
        if(this.questions.length<2){
            this.utill.showError("You must have atleast one question in a quiz!");
            return;
        }
        console.log(this.questions[index]);
        if(!this.questions[index].hasOwnProperty('_id')){
          this.questions.splice(index,1);
          return;
        }
        this.quizService.deleteQuestion(this.questions[index]._id)
            .subscribe((resp:any)=>{
                if (resp.success) {
                    this.questions.splice(index, 1);
                    this.utill.showSuccess('Your question has been successfully deleted');
                }

            })
    }

}