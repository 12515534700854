import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { UtilService } from './util.service';

@Injectable({
  providedIn: 'root'
})
export class AnswerService {

  constructor(
    private _http: HttpClient,
    private util: UtilService
  ) { }

  getQuestion(question_id){
  	return this._http.get(this.util.url+'/api/answer_questions/'+question_id);
  }

  answerQuestion(data){
  	return this._http.post(this.util.url+'/api-v2/answer_questions',data);
  }

  getResult(section_id){
  	return this._http.get(this.util.url+'/api-v2/sections/'+section_id+'/result')
  }
}
