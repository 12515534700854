import { Component, OnInit, Input } from '@angular/core';
import { AlertController, PopoverController } from '@ionic/angular';
import { AuthenticationService, User } from './../../services/authentication.service';
import { UtilService } from './../../services/util.service';
import { TermService } from './../../services/term.service';
import { Router, RouterEvent } from '@angular/router';
import { SocketService } from './../../services/socket.service';
import { NavController, ModalController, ActionSheetController, Events } from '@ionic/angular';

@Component({
  selector: 'app-share-term',
  templateUrl: './share-term.component.html',
  styleUrls: ['./share-term.component.scss'],
})
export class ShareTermComponent implements OnInit {
   @Input() term: any;
   @Input() type: any = 'share';
   typeText = 'share';
   typeButtonText = 'Share';
   shareEmail:any;
   user: any;
   
  constructor(
  	private router: Router, 
  	private popoverCtrl: PopoverController, 
  	private authService: AuthenticationService,
  	private util: UtilService,
  	private modalCtrl: ModalController,
  	private termService: TermService,
  	private alertController: AlertController,
  	private socket: SocketService
  ) { }

  ngOnInit() {
  	this.authService.currentUser.subscribe(user => {
      this.user = user;
    });
  	if(this.type == 'invite'){
  		this.typeText = 'invite Co-Teaching';
  		this.typeButtonText = 'Invite';
  	}
  	this.socket.termUpdate().subscribe((data:any)=>{
      if(data && data.term_id && this.term._id == data.term_id){
        data.fields.forEach((field:any)=>{
          this.term[field.key] = field.value;
        })
      }
	});
  }


  closeModal() {
    this.modalCtrl.dismiss();
  }

  deleteCoTeacher(index,type){
  	var val = '';
  	if(type == 'owners')
  		val = this.term[type][index]._id;
  	else if(type == 'share_owners')
  		val = this.term[type][index];

  	this.util.showLoadingHandler("Removing Co-Teacher!");
  	this.termService.deleteCoTeacher(this.term._id,val).subscribe((resp:any)=>{
  		this.util.hideLoadingHandler();
  		if(resp.success){
  			this.term[type].splice(index,1);
  		}
  	})
  }

  deleteAllShared(){
  	this.util.showLoadingHandler();
  	this.termService.deleteOwners(this.term._id).subscribe((resp:any)=>{
  		this.util.hideLoadingHandler();
  		this.util.showMessage(resp);
  		if(resp.success){
  			this.shareEmail = '';
  			this.term.owners = resp.term.owners;
  			this.term.share_owners = resp.term.share_owners;
  		}
  	})
  }

  // refactor
  share(){
  	this.util.showLoadingHandler();
  	if(this.type == 'share'){
  		this.termService.shareTerm(this.term._id,this.shareEmail).subscribe((resp:any)=>{
	  		this.util.hideLoadingHandler();
	  		this.util.showMessage(resp);
	  		if(resp.success){
				this.shareEmail = '';
				this.term.owners = resp.term.owners;
				this.term.share_owners = resp.term.share_owners;
			}
	  	})
  	}else if(this.type == 'invite'){
  		this.termService.inviteCoTeacher(this.term._id,this.shareEmail).subscribe((resp:any)=>{
	  		this.util.hideLoadingHandler();
	  		this.util.showMessage(resp);
	  		if(resp.success){
	  			this.shareEmail = '';
				this.term.owners = resp.term.owners;
				this.term.share_owners = resp.term.share_owners;
			}
	  	})
  	}
  }

}