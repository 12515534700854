import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from './services/auth-guard.service';
import { SharedDirectivesModule } from './directives/shared-directives.module';
import { IonicStorageModule } from '@ionic/storage';

const routes: Routes = [
  { path: '', redirectTo: '', pathMatch: 'full' },
  { path: 'login', loadChildren: 'src/app/public/login/login.module#LoginPageModule' },
  { path: 'register', loadChildren: 'src/app/public/register/register.module#RegisterPageModule' },
  { path: 'forget-password', loadChildren: 'src/app/public/forget-password/forget-password.module#ForgetPasswordPageModule' },
  {
    path: 'members',
    canActivate: [AuthGuardService],
    loadChildren: 'src/app/members/member-routing.module#MemberRoutingModule'
  },
  {
    path: 'activate',
    loadChildren: () => import('./public/activate/activate.module').then( m => m.ActivatePageModule)
  },
  {
    path: 'member-confirm',
    loadChildren: () => import('./public/member-confirm/member-confirm.module').then( m => m.MemberConfirmPageModule)
  }
];

@NgModule({
  imports: [
    SharedDirectivesModule,
    RouterModule.forRoot(routes),
    IonicStorageModule.forRoot()
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
