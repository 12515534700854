import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { UtilService } from './util.service';


@Injectable({
  providedIn: 'root'
})
export class StudentService {

  constructor(
    private _http: HttpClient,
  	private util: UtilService
  ) { }


  getUpcomingClasses(){
    return this._http.get(this.util.url + '/api/student/upcoming-classes');
  }

  getStats(){
    return this._http.get(this.util.url + '/api/student/stats');
  }

  getArchiveClasses(student_id?:any){
    let url = this.util.url + '/api/student/myclasses/archive';
    if(student_id)
      url += '/'+student_id;
    return this._http.get(url);
  }

  updateTopicAnswer(session_id:any,topic_id:any,value:any){
    return this._http.post(this.util.url + '/api/student/knowledge',{ session_id, topic_id, value});
  }

  getCurrentSemesterClasses(student_id?:any){
    let url = this.util.url + '/api/student/current-classes';
    if(student_id)
      url += '/'+student_id;
    return this._http.get(url);
  }

  getActiveClass(){
    return this._http.get(this.util.url + '/api/student/active-classes');
  }

  sendAnonymousMessage(data) {
    return this._http.post(this.util.url + '/api/student/anonymous_comment', data);
  }

  getAllowAnonymounsComment(classID) {
    return this._http.get(this.util.url + '/api/student/allow-anonymous-comment/' + classID);
  }

  attend(code, qr){
    return this._http.post(this.util.url + '/api/attend', {code, qr});
  }

  attendBeacon(beacon, sessions){
    return this._http.post(this.util.url + '/api/attend/beacon', {
      'beacon_id': beacon,
      'sessions': sessions
    })
  }

  rating(classID,sessionID){
    return this._http.get(this.util.url + '/api/student/rating/' + classID + '/' + sessionID);
  }

  rate(eventID,questionID,answerID){
    return this._http.get(this.util.url + '/api/student/answer/' + eventID + '/' + questionID + '/' + answerID);
  }
}
