import { Component, OnInit, Input } from '@angular/core';
import { AlertController, Platform, PopoverController } from '@ionic/angular';
import { AuthenticationService, User } from './../../services/authentication.service';
import { UtilService } from './../../services/util.service';
import { TermService } from './../../services/term.service';
import { Router, RouterEvent } from '@angular/router';
import { SocketService } from './../../services/socket.service';
import { NavController, ModalController, ActionSheetController, Events } from '@ionic/angular';

@Component({
	selector: 'app-term-participants',
	templateUrl: './term-participants.component.html',
	styleUrls: ['./term-participants.component.scss'],
})
export class TermParticipantsComponent implements OnInit {
	@Input() term: any;
	query: String = '';
	isMobile = false;

	constructor(
		private router: Router, 
		private popoverCtrl: PopoverController, 
		private authService: AuthenticationService,
		private util: UtilService,
		private modalCtrl: ModalController,
		private termService: TermService,
		private alertController: AlertController,
		private socket: SocketService,
		private platform: Platform,
	) { }

	ngOnInit() {
		this._arrangeParticipantIds();
		this.socket.termUpdate().subscribe((data:any)=>{
			if(data && data.term_id && this.term._id == data.term_id){
				data.fields.forEach((field:any)=>{
					this.term[field.key] = field.value;
				})
				this._arrangeParticipantIds();
			}
		});

		if(!this.platform.is('desktop')){
			this.isMobile = true
		}
	}

	_arrangeParticipantIds(){
		if(this.term.allowed_ids){
			this.term.participants = [];
			this.term.allowed_ids.forEach(id=>{
				let temp_sub: any;
				temp_sub = this.term.subscribers.find(sub=>{
					return sub.identification_id == id;
				})
				if(!temp_sub)
					temp_sub = {};

				temp_sub['allowed_id'] = id;
				this.term.participants.push(temp_sub);
			})
			this.term.filteredParticipants = this.term.participants;
		}else{
			this.term.participants = this.term.subscribers;
			this.term.filteredParticipants = this.term.subscribers;
		}
	}

	filterParticipants($event){
		this.query = $event.target.value;
		this.term.filteredParticipants = this.term.participants.filter((participant: any) => {
		return (participant.user && participant.user.first_name && participant.user.first_name.toLowerCase().indexOf(this.query.toLowerCase()) > -1) || 
				(participant.user && participant.user.last_name && participant.user.last_name.toLowerCase().indexOf(this.query.toLowerCase()) > -1) || 
				(participant.user && participant.user.email && participant.user.email.toLowerCase().indexOf(this.query.toLowerCase()) > -1) || 
				(participant.allowed_id && participant.allowed_id.toString().toLowerCase().indexOf(this.query.toString().toLowerCase()) > -1);
		});
	}


	async deleteParticipant(student_id){
		const alert = await this.alertController.create({
			header: 'Delete Participant',
			message: 'Are you sure that you want to delete this participant?',
			buttons: [
			{
				text: 'Cancel',
				role: 'cancel',
				cssClass: 'secondary',
				handler: (blah) => {
					// console.log('Confirm Cancel: blah');
				}
			}, {
				text: 'Delete',
				handler: () => {
					this._deleteParticipant(student_id)
				}
			}
			]
		});
	
		await alert.present();
	}

	_deleteParticipant(student_id){
		if(student_id){
			this.termService.deleteSubscriber(this.term._id, student_id).subscribe((resp:any)=>{
				this.termService.deleteAllowedId(this.term._id, student_id).subscribe((resp:any)=>{
					this.util.showMessage(resp);
					this._arrangeParticipantIds();
				})
			})
		} else {
			this.util.showError('There is no Participant to delete')
		}
	}

}