import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'secondsToTime'
})
export class SecondsToTimePipe implements PipeTransform {

  transform(value: number): Date {
  	 var date = new Date(0,0,0,0,0,0,0);
	 date.setSeconds(value);
	 return date;

  }

}
