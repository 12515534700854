import { Component, OnInit, Input } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import * as filter from 'bad-words';
import { UserService } from 'src/app/services/user.service';
import { UtilService } from 'src/app/services/util.service';
import { User, AuthenticationService } from 'src/app/services/authentication.service';
import { StudentService } from 'src/app/services/student.service';


@Component({
  selector: 'app-anonymous-comment',
  templateUrl: './anonymous-comment.component.html',
  styleUrls: ['./anonymous-comment.component.scss'],
})
export class AnonymousCommentComponent implements OnInit {
  @Input() classId;
  @Input() instructorId;
  badWordFilter = new filter();
  comment: string;
  user: any;
  charsLeft: any = 200;

  constructor(
    private modalController: ModalController,
    public toastController: ToastController,
    private userService: UserService,
    private util: UtilService,
    private studentService: StudentService,
    private authService: AuthenticationService
  ) { }

  ngOnInit() {
    this.authService.currentUser.subscribe(user => {
      this.user = user;
    });
  }

  async closeModal() {
    await this.modalController.dismiss();
  }

  async sendMessage() {
    // console.log(this.badWordFilter.clean(this.comment));
    // console.log("instructor id: ");
    // console.log(this.instructorId._id);

    if (this.comment && this.comment.length <= 200) {
      this.studentService.sendAnonymousMessage({
        classId: this.classId,
        instructorId: this.instructorId,
        comment: this.badWordFilter.clean(this.comment),
        student: this.user
      }).subscribe((resp: any) => {
        this.util.showMessage(resp);
      });
      await this.modalController.dismiss();
    } else {
      this.presentToast('The comment can not be empty, and have max 200 characters to send', 'danger');
    }
  }

  async presentToast(message: string, status: string) {
    const toast = await this.toastController.create({
      message,
      duration: 2000,
      animated: true,
      color: status
    });
    toast.present();
  }

  countDownChars() {
    this.charsLeft = 200 - this.comment.length;
  }

}
