import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
@Component({
  selector: 'app-quiz-help',
  templateUrl: './quiz-help.component.html',
  styleUrls: ['./quiz-help.component.scss'],
})
export class QuizHelpComponent implements OnInit {

  constructor(private modalCtrl: ModalController) { }

  ngOnInit() {}

  closeModal() {
    this.modalCtrl.dismiss();
  }
}
