import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UtilService } from './util.service';

@Injectable({
  providedIn: 'root'
})
export class PushNotificationService {

  public notifications: BehaviorSubject<Object> = new BehaviorSubject(false);

  constructor(private util: UtilService) { }

  init() {
    if(!(<any> window).PushNotification)
      return;
    var that = this;

    let push = (<any> window).PushNotification.init({
      android: { 
        senderID: '480499725053'
      },
      ios: {
        senderID: '480499725053',
        alert: 'true',
        badge: true,
        sound: 'false'
      }
    });

    // testing
    push.on('registration', (data) => {
      // console.log("here: ", data);
      // console.log(data.registrationId);
      // console.log(data.registrationType);
      
      that.util.setStorage(that.util.pushID, data.registrationId);
    });

    push.on('notification', (data) => {

      console.log(data.alert);
      console.log(data.message);
      console.log(data.title);
      console.log(data.count);
      console.log(data.sound);
      console.log(data.image);
      console.log(data.additionalData);    

      this.notifications.next(data);

    });

    push.on('error', (err) => {
      console.log(err);
    });

  }
}
