import { Component, OnInit } from '@angular/core';
import { NavParams, AlertController, PopoverController, ModalController } from '@ionic/angular';
import { AuthenticationService, User } from './../../services/authentication.service';
import { UtilService } from './../../services/util.service';
import { TermService } from './../../services/term.service';
import { QuizService } from './../../services/quiz.service';
import { Router, RouterEvent } from '@angular/router';
import { SocketService } from './../../services/socket.service';
import { StartQuiz } from '../start-quiz/start-quiz.component';
import { ShareQuizComponent } from '../share-quiz/share-quiz.component';

function getWindow (): any {
   return window;
 }

@Component({
  selector: 'app-quiz-popover',
  templateUrl: './quiz-popover.component.html',
  styleUrls: ['./quiz-popover.component.scss'],
})
export class QuizPopoverComponent implements OnInit {
	quiz:any;
  user:any;
  window: any;

  constructor(
  	private router: Router, 
  	private popoverCtrl: PopoverController, 
    private modalController: ModalController,
  	private authService: AuthenticationService,
  	private navParams: NavParams,
  	private util: UtilService,
  	private termService: TermService,
  	private alertController: AlertController,
  	private socket: SocketService,
  	private quizService: QuizService
  ) { }

  ngOnInit() {
    this.window = getWindow();

    this.authService.currentUser.subscribe(user => {
      this.user = user;
    });
  	this.quiz = this.navParams.get('quiz');
 //  	this.socket.quizUpdate().subscribe(data=>{
 //      if(data && data.quiz_id && this.quiz._id == data.quiz_id){
 //      	this.quiz[data.field.key] = data.field.value;
 //      }
	// });
  }

  async onDismiss() {
    try {
        await this.popoverCtrl.dismiss();
    } catch (e) {
        // click more than one time popover throws error, so ignore...
    }
  }

  // cloneQuizAlert:any;
  // async cloneQuiz() {
  //   var that = this;
  // 	that.cloneQuizAlert = await this.alertController.create({
		// 			      'header': 'Clone Quiz!',
		// 			      'subHeader': '',
		// 			      'inputs': [{
		// 			        	'name':'quizName',
		// 			        	'value': this.quiz.name+' Copy'
		// 			        }],
		// 			      'buttons':[{
		// 			        	'text':'Cancel',
		// 			        	'role':'cancel',
		// 			        	handler:data=>{}
		// 			            },{
		// 			            	'text': 'Clone',
		// 			            	handler:data=>{
					            		
		// 			            		this.util.showLoadingHandler("Cloning Quiz!");
		// 			            		this.quizService.clone(this.quiz._id,data.quizName).subscribe((resp:any)=>{
		// 			            			this.util.hideLoadingHandler();
		// 			            			this.util.showMessage(resp);
		// 			            			if(resp.success){
		// 			            				that.cloneQuizAlert.dismiss();
		// 			            				this.router.navigateByUrl('/members/l/quiz/'+resp.quiz._id);
		// 			            			}
		// 			            		})
		// 			            		return false;
		// 			            	}
		// 			            }]
		// 				    });
  // 	await that.cloneQuizAlert.present();
  // }


  editCloneQuiz(action){
    this.router.navigateByUrl('members/l/create-quiz',{state:{'quiz':this.quiz,'action':action}});
    this.onDismiss();
  }

  emailReport(){
    this.util.confirmAlert(
      'Send the report',
      '',
      'This will send you a report of correctness of all question in this quiz',
      [{
          'text':'Cancel',
          'role':'cancel',
          handler:data=>{}
            },{
              'text': 'Send',
              handler:data=>{
                
                this.util.showLoadingHandler("Sending Report!");
                this.quizService.report(this.quiz._id).subscribe((resp:any)=>{
                  this.util.hideLoadingHandler();
                  this.util.showMessage(resp.report);
                })
              }
            }])
  }

  downloadQuestions(){
    this.util.getStorage(this.util.authKey).then(token=>{
        token = token.replace(/\"/g,"");
    	  window.open(this.util.url+'/api/quizzes/download-questions/'+this.quiz._id+'?token='+token,'_blank');
    });
  }


  previewQuiz(){
    this.util.showLoadingHandler("Getting sample quiz!");
  	this.quizService.getPreviewQuiz(this.quiz._id).subscribe((resp:any)=>{
      this.util.hideLoadingHandler();
      if(resp.success){
        this.openModalWithData(resp.quiz_data,resp.student_quiz,resp.studentQuizMeta);
      }else{
        this.util.showMessage(resp);
      }
    })
  }

  async openModalWithData(quiz,section,student_quiz_meta) {
    const modal = await this.modalController.create({
      component: StartQuiz,
      componentProps: {
        'preview' : true,
        'quiz_data': quiz,
        'questions': section,
        student_quiz_meta
      }
    });

    modal.onWillDismiss().then(dataReturned => {
    });

    return await modal.present().then(_ => {
    });
  }

  async shareQuiz(){
    const modal = await this.modalController.create({
      component: ShareQuizComponent,
      componentProps: { quiz: [this.quiz], action: 'share_quiz' }
    });
    modal.onDidDismiss().then((modalData) => {
      
    });
    return await modal.present();
  }

  async inviteCoTeachQuiz(){
    // console.log("invite co-teach clciked");
    
    const modal = await this.modalController.create({
      component: ShareQuizComponent,
      componentProps: { quiz: this.quiz, action: 'share_co_teaching' }
    });
    modal.onDidDismiss().then((modalData) => {
      
    });
    return await modal.present();
  }
}