import { AlertController } from '@ionic/angular';
import { HttpResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
 
import { Observable, throwError, from } from 'rxjs';
import { catchError, mergeMap, map, switchMap } from 'rxjs/operators';
import { UtilService } from './util.service';
import { AuthenticationService } from './authentication.service';
import { Storage } from '@ionic/storage';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
 
    constructor(
      private authService: AuthenticationService, 
      private util: UtilService, 
      private alertCtrl: AlertController,
      private storage: Storage
    ) { }
 
    // Intercepts all HTTP requests!
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
 
        // let promise = this.util.getStorage(this.util.authKey);
        // let clonedReq = this.addToken(request, promise);
        //         return next.handle(clonedReq).pipe(
        //             catchError(error => {
        //                 if (error instanceof HttpErrorResponse) {
        //                   if (error.status === 401 || error.status === 403) {
        //                     this.authService.logout();
        //                     this.util.hideLoadingHandler();
        //                   }
        //                 }
        //                 // Perhaps display an error for specific status codes here already?
        //                 // let msg = error.message;
 
        //                 // let alert = this.alertCtrl.create({
        //                 //     title: error.name,
        //                 //     message: msg,
        //                 //     buttons: ['OK']
        //                 // });
        //                 // alert.present();
 
        //                 // Pass the error to the caller of the function
        //                 return throwError(error);
        //             })
        //         ).pipe(map((event: HttpEvent<any>) => {
        //           if(event instanceof HttpResponse){
        //             // print all http responses to console
        //             // console.log(event.body);
        //           }
        //           return event;
        //         }));

        return from(this.util.getStorage(this.util.authKey)).pipe(
          switchMap(token=>{

                let clone = request.clone();
                if (token) {
                    var key = this.util.authKey;
                    var req_header = {
                          Accept: `application/json`,
                          // 'Content-Type': `application/json`,
                      };
                    req_header[key] = token.replace(/\"/g,"");
                    clone = request.clone({
                        setHeaders: req_header
                    });
                    // console.log(clone);
                    // return clone;
                }

                return next.handle(clone).pipe(
                    catchError(error => {
                        if (error instanceof HttpErrorResponse) {
                          if (error.status === 401 || error.status === 403) {
                            this.authService.logout();
                            this.util.hideLoadingHandler();
                          }
                        }
                        // Perhaps display an error for specific status codes here already?
                        // let msg = error.message;
 
                        // let alert = this.alertCtrl.create({
                        //     title: error.name,
                        //     message: msg,
                        //     buttons: ['OK']
                        // });
                        // alert.present();
 
                        // Pass the error to the caller of the function
                        return throwError(error);
                    })
                ).pipe(map((event: HttpEvent<any>) => {
                  if(event instanceof HttpResponse){
                    // print all http responses to console
                    // console.log(event.body);
                  }
                  return event;
                }))
        }));
       // var observableFromPromise =  from(promise);
       //  return observableFromPromise.pipe(
       //      mergeMap(token => {
       //          let clonedReq = this.addToken(request, token);
       //          return next.handle(clonedReq).pipe(
       //              catchError(error => {
       //                  if (error instanceof HttpErrorResponse) {
       //                    if (error.status === 401 || error.status === 403) {
       //                      this.authService.logout();
       //                      this.util.hideLoadingHandler();
       //                    }
       //                  }
       //                  // Perhaps display an error for specific status codes here already?
       //                  // let msg = error.message;
 
       //                  // let alert = this.alertCtrl.create({
       //                  //     title: error.name,
       //                  //     message: msg,
       //                  //     buttons: ['OK']
       //                  // });
       //                  // alert.present();
 
       //                  // Pass the error to the caller of the function
       //                  return throwError(error);
       //              })
       //          );
       //      })).pipe(map((event: HttpEvent<any>) => {
       //        if(event instanceof HttpResponse){
       //          // print all http responses to console
       //          // console.log(event.body);
       //        }
       //        return event;
       //      }));
    }
 
    // Adds the token to your headers if it exists
    private addToken(request: HttpRequest<any>, token: any) {
        var key = this.util.authKey;
        var req_header = {
                  Accept: `application/json`,
                  // 'Content-Type': `application/json`,
              };
        req_header[key] = token;

        if (token) {
            let clone: HttpRequest<any>;
            clone = request.clone({
                setHeaders: req_header
            });
            // console.log(clone);
            return clone;
        }
 
        return request;
    }
}
