import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { FormsModule } from '@angular/forms';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { IonicStorageModule } from '@ionic/storage';
import { SharedDirectivesModule } from './directives/shared-directives.module';
import { SharedAppComponents } from './components/shared-app-components.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HeaderInterceptor } from './services/headerinterceptor';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
// import { AvatarModule } from 'ng2-avatar';
import { AvatarModule } from 'ngx-avatar';
import { Camera } from '@ionic-native/camera/ngx';
import { File } from '@ionic-native/file/ngx';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Push } from '@ionic-native/push/ngx';
import { FCM } from '@ionic-native/fcm/ngx';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { QRCodeModule } from 'angularx-qrcode';
import { ChartsModule, ThemeService } from 'ng2-charts';
// import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
// import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faCoffee, fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { LocationAccuracy } from '@ionic-native/location-accuracy/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { APP_BASE_HREF, LocationStrategy, PathLocationStrategy, HashLocationStrategy } from '@angular/common';


// library.add(fas,far,fab)// add all icons


@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    FontAwesomeModule,
    SharedDirectivesModule,
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    SharedAppComponents,
    IonicStorageModule.forRoot(),
    HttpClientModule,
    AvatarModule.forRoot(),
    NgxChartsModule,
    BrowserAnimationsModule,
    FormsModule,
    NgbModule,
    QRCodeModule,
    ChartsModule
  ],
  providers: [
    LocationAccuracy,
    Geolocation,
    StatusBar,
    SplashScreen,
    // { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptor, multi: true },
    InAppBrowser,
    Camera,
    File,
    Push,
    FCM,
    ThemeService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas,far,fab);
    library.addIcons(faCoffee);
  }
}
