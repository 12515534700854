import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-preview-quiz',
  templateUrl: './preview-quiz.component.html',
  styleUrls: ['./preview-quiz.component.scss'],
})
export class PreviewQuizComponent implements OnInit {
  @Input() quiz_data: any;
  @Input() questions: any;
  @Input() student_quiz_meta: any;
  @Input() preview;

  constructor() { }

  ngOnInit() {}

}
