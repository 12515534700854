import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { UtilService } from './util.service';

export class Notification {
    type: NotificationType;
    message: string;
    delay: number;
    keepAfterRouteChange: boolean;
}

export enum NotificationType {
    Success,
    Error,
    Info,
    Warning
}

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  private subject = new Subject<Notification>();
	// private keepAfterRouteChange = false;
	// private delay = 4000;

	private options = {
		delay: 40000,
		keepAfterRouteChange: true
	};

	constructor(
		private router: Router,
		private _http: HttpClient,
		private util: UtilService
	) {
	    // clear alert messages on route change unless 'keepAfterRouteChange' flag is true
	    router.events.subscribe(event => {
	        if (event instanceof NavigationStart) {
	            if (this.options.keepAfterRouteChange) {
	                // only keep for a single route change
	                this.options.keepAfterRouteChange = false;
	            } else {
	                // clear alert messages
	                this.clear();
	            }
	        }
	    });
	}

	getNotification(): Observable<any> {
	    return this.subject.asObservable();
	}

	success(message: string, opts: object = {}) {
		Object.assign(this.options, opts);
	    this.notification(NotificationType.Success, message, opts);
	}

	error(message: string, opts: object = {}) {
		Object.assign(this.options, opts);
	    this.notification(NotificationType.Error, message, opts);
	}

	info(message: string, opts: object = {}) {
		Object.assign(this.options, opts);
	    this.notification(NotificationType.Info, message, opts);
	}

	warn(message: string, opts: object = {}) {
	    this.notification(NotificationType.Warning, message, opts);
	}

	notification(type: NotificationType, message: string, opts: object = {}) {
		Object.assign(this.options, opts);
		this.subject.next(<Notification>{ type: type, message: message, delay: this.options.delay,  keepAfterRouteChange: this.options.keepAfterRouteChange});
	}

	clear() {
	    // clear alerts
	    this.subject.next();
	}

	getNotifications() {
		return this._http.get(this.util.url + '/api/users/notifications'); 
	}

	deleteNotification(id) {
		return this._http.delete(this.util.url + '/api/users/notifications/' + id); 
	}
}
