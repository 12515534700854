import { Platform } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Storage } from '@ionic/storage';
import { UtilService } from './util.service';
import { UserService } from './user.service';
import { AdminService } from './admin.service';
import { HttpClient, HttpParams } from '@angular/common/http';

export interface Options{
	adfs_enabled: boolean,
	adfs_login_url: boolean,
	logoUrl: string,
  	siteName: string,
	version: string,
	platform: any,
  	domain: string,
}


@Injectable({
  providedIn: 'root'
})
export class OptionServiceService {
  public currentOptions: BehaviorSubject<Options> = new BehaviorSubject(null);

  constructor(
    private _http: HttpClient,
    private adminService: AdminService,
    private userService: UserService,
  	private storage: Storage, 
  	private plt: Platform, 
  	private util: UtilService
  ) { 
  	this.plt.ready().then(()=>{
  		this.load_options();
  	})
  }

  load_options(){
    this._http.get(this.util.url + '/api/options').subscribe((resp:any)=>{
    	if(resp.options){
	        this.util.setStorage(this.util.optionsKey, resp.options)
	        this.currentOptions.next(resp.options);
	      }else{
	        this.util.showMessage(resp);
	      }
    })
  }

  getOptionsSubject(){
  	return this.currentOptions.asObservable();
  }

}
