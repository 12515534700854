import { Component, OnInit } from '@angular/core';
import { NavParams, AlertController, PopoverController } from '@ionic/angular';
import { AuthenticationService, User } from './../../services/authentication.service';
import { UtilService } from './../../services/util.service';
import { TermService } from './../../services/term.service';
import { Router, RouterEvent } from '@angular/router';
import { SocketService } from './../../services/socket.service';
import { NavController, ModalController, ActionSheetController, Events } from '@ionic/angular';

@Component({
  selector: 'app-term-participants-modal',
  templateUrl: './term-participants-modal.component.html',
  styleUrls: ['./term-participants-modal.component.scss'],
})
export class TermParticipantsModalComponent implements OnInit {
	term:any;
	participantIds: any;
  constructor(
  	private router: Router, 
  	private popoverCtrl: PopoverController, 
  	private authService: AuthenticationService,
  	private navParams: NavParams,
  	private util: UtilService,
  	private modalCtrl: ModalController,
  	private termService: TermService,
  	private alertController: AlertController,
  	private socket: SocketService
  ) { }

  ngOnInit() {
  	this.term = this.navParams.get('term');
  	this.util.hideLoadingHandler();
 //  	this.socket.termUpdate().subscribe(data=>{
 //      if(data && data.term_id && this.term._id == data.term_id){
 //      	this.term[data.field.key] = data.field.value;
 //      }
	// });
  }

  addParticipants(){
  	let ids = this.participantIds.split(/[\r\n]+/);
  	if(!ids)
  		this.util.showError("Please provide id!");
  	else
		this.termService.addAllowedIds(this.term._id,ids).subscribe((resp:any)=>{
			this.util.showMessage(resp);
			this.participantIds = '';
		})
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

}
