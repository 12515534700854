import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { UtilService } from './util.service';

@Injectable({
  providedIn: 'root'
})
export class InstructorService {

  
  constructor(
    private _http: HttpClient,
  	private util: UtilService
  ) { }

  private getClasses(type){
  	return this._http.get(this.util.url + '/api/class/classes/' + type);
  }

  getActiveClasses(){
  	return this.getClasses('active');
  }

  getAllClasses(){
  	return this.getClasses('all');
  }

  getUpcomingClasses(){
    return this._http.get(this.util.url + '/api/class/upcoming-classes');
  }

  getPastClasses(page, filters){
   return this._http.post(this.util.url + '/api/class/past-classes/'+page, filters); 
  }

  getCurrentClasses(page, filters){
   page = page || 0;
   return this._http.post(this.util.url + '/api/class/current-classes/'+page, filters); 
  }

  //semester course year
  getFilterData(){
    return this._http.get(this.util.url + '/api/class/filter-data');
  }

  getClassDetail(id){
    return this._http.get(this.util.url + '/api/class/'+id);
  }
  
  getActiveSessionById(session_id: any) {
    return this._http.get(this.util.url + '/api/session/' + session_id);
  }

  updateBeaconStatus(session_id: any, status: any){
    return this._http.put(this.util.url + '/api/session/' + session_id + '/update-beacon/' + status, {});
  }

  cancelSession(session_id, cancelComment) {
    // return this._http.delete(this.util.url + '/api/session/' + session_id, {body: {'cancelComment': cancelComment}});
    return this._http.delete(this.util.url + '/api/session/' + session_id);
  }

  addTopic(data) {
    return this._http.post(this.util.url + '/api/sessionmeta/addtopic', data);
  }

  startTopic(session_id, topic_id) {
    return this._http.get(this.util.url + '/api/sessionmeta/start-topic/' + session_id + '/' + topic_id);
  }

  stopTopic(session_id, topic_id) {
    return this._http.get(this.util.url + '/api/sessionmeta/end-topic/' + session_id + '/' + topic_id);
  }

  attendStudent(session_id,identification,status){
    return this._http.put(this.util.url + '/api/session/attend-student',{session_id,identification,status});
  }

  getStats(){
    return this._http.get(this.util.url + '/api/class/stats');
  }

  searchStudent(query){
    return this._http.get(this.util.url + '/api/users/autocomplete/' + query);
  }

  studentDetail(id){
     return this._http.get(this.util.url + '/api/users/student/' + id); 
  }

  addStudentNote(id: any, data: any){
    return this._http.post(this.util.url + '/api/users/notes/' + id, data);
  }

  getStudentNotes(id: any){
    return this._http.get(this.util.url + '/api/users/notes/' + id + '/0');
  }

  deleteStudentNote(id: any){
    return this._http.delete(this.util.url + '/api/users/notes/' + id);
  }
}
