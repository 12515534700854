import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { UtilService } from './util.service';

@Injectable({
  providedIn: 'root'
})
export class TermService {

  constructor(
    private _http: HttpClient,
    private util: UtilService
  ) { }

  create(name){
    return this._http.post(this.util.url+'/api-v2/terms',{name});
  }

  subscribeTerm(identification, term_id){
  	return this._http.post(this.util.url+'/api/terms/subscribe/'+term_id+'/'+identification,{});
  }

  getActivePage(page){
  	return this._http.get(this.util.url+'/api/terms/page/0/'+page);
  }

  getArchivedPage(page){
  	return this._http.get(this.util.url+'/api/terms/page/1/'+page);
  }

  getTerm(term_id){
    return this._http.get(this.util.url+'/api-v2/terms/'+term_id);
  }

  update(id, data){
    return this._http.put(this.util.url+'/api-v2/terms/'+id,data);
  }

  updateAllowSelfRegister(id, status){
    return this._http.put(this.util.url+'/api-v2/terms/'+id+'/update-allow-self-register',{status});
  }

  updateStatus(id, status){
    return this._http.get(this.util.url+'/api-v2/terms/'+id+'/update-status/'+status);
  }

  clone(id, newName){
    return this._http.get(this.util.url+'/api-v2/terms/'+id+'/clone/'+newName);
  }

  getTermNames(){
    return this._http.get(this.util.url+'/api-v2/terms/names');
  }

  getTermQuizNames(term_id){
    return this._http.get(this.util.url+'/api-v2/terms/'+term_id+'/quizzes-names');
  }

  report(id, quiz_weight, quizzes, ids, number_of_best_quizzes){
    var data = {
        'term_id': id,
        'quiz_weight': quiz_weight,
        'quizzes': quizzes,
        'ids': ids,
        'number_of_best_quizzes': number_of_best_quizzes,
        'download': true
      }
    return this._http.post(this.util.url+'/api-v2/generate_report', data, {responseType : 'blob'});
  }

  addAllowedIds(term_id, allowedIds){
    return this._http.put(this.util.url+'/api-v2/terms/'+term_id+'/add-allowed-id',{'ids':allowedIds});
  }

  createUser(term_id, user){
    return this._http.post(this.util.url+'/api-v2/terms/create-user',{
      'term': term_id,
      user
    });
  }

  createUserWithCsv(file, termId, subscribed, idAllowed) {
    var data = new FormData()
    var url = this.util.url + '/api-v2/terms/csv/upload'
    data.append('file', file)
    data.append('term', termId)
    data.append('subscribed', subscribed)
    data.append('idAllowed', idAllowed)

    return this._http.post(url, data,{
      // headers: new HttpHeaders({"Content-Type": "multipart/form-data"})
    })
  }

  getParticipantsWithCsv(file, termId) {
    var data = new FormData()
    var url = this.util.url + '/api-v2/terms/csv/upload/participants'
    data.append('file', file)
    data.append('term', termId)

    return this._http.post(url, data,{
      // headers: new HttpHeaders({"Content-Type": "multipart/form-data"})
    })
  }

  /*
    All parameters are optional
    term_id to return single term
    fields to return fields
    quizcount if you like to include quiz count on founded terms
  */
  getTermswithFields(term_id?,fields?,quizcount?){
    return this._http.post(this.util.url+'/api-v2/terms/request-fields',{
      fields,
      term_id,
      quizcount
    })
  }

  inviteCoTeacher(term_id,email){
    return this._http.put(this.util.url+'/api-v2/terms/'+term_id+'/add-owner',{'emails':[email],'is_coteaching':true});
  }

  deleteCoTeacher(term_id,user_id){
    return this._http.put(this.util.url+'/api-v2/terms/'+term_id+'/delete-owner',{'id':user_id});
  }

  deleteOwners(term_id){
    return this._http.delete(this.util.url+'/api-v2/terms/'+term_id+'/delete-owners');
  }

  shareTerm(term_id,email){
    return this._http.put(this.util.url+'/api-v2/terms/'+term_id+'/add-owner',{'emails':[email],'is_coteaching':false}); 
  }

  deleteAllowedId(term_id, student_id){
    return this._http.put(this.util.url+'/api-v2/terms/'+term_id+'/delete-allowed-id', {'student_id': student_id});
  }

  deleteSubscriber(term_id, student_id){
    return this._http.put(this.util.url+'/api-v2/terms/'+term_id+'/delete-subscriber', {'student_id': student_id});
  }
}
