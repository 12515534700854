import { Component, OnInit, NgZone, Input } from '@angular/core';
import { NavParams, NavController, ModalController, ActionSheetController } from '@ionic/angular';
import { UserService } from './../../../services/user.service';
import { AlertController, Platform } from '@ionic/angular';
import { AuthenticationService, User } from './../../../services/authentication.service';
import { UtilService } from './../../../services/util.service';
import { QuizService } from './../../../services/quiz.service';

function getWindow (): any {
  return window;
}

@Component({
  selector: 'app-section-beacons',
  templateUrl: './section-beacons.component.html',
  styleUrls: ['./section-beacons.component.scss'],
})
export class SectionBeaconsComponent implements OnInit {
  @Input() selectedBeacons = [];	
  @Input() sectionId: any;
  isScanning: boolean = false;
  window :any;
  devices : any = [];
  user: any;
  section: any;

  constructor(
  	private navParams: NavParams,
  	private util: UtilService,
  	private userService: UserService,
  	private modalCtrl: ModalController,
  	private platform: Platform,
  	private authService: AuthenticationService,
    private zone: NgZone,
    private quizService: QuizService
  ) { }

  ngOnInit() {
    this.selectedBeacons = this.navParams.get('selectedBeacons');
  	var that = this;
    that.window = getWindow();

  	this.authService.currentUser.subscribe(user => {
      this.user = user;
    });

    this.quizService.sectionAllBeacons(this.sectionId).subscribe((section:any) => {
      this.section = section;
    })

  	this.startScanningForBeacons();
  }

  updateSelected(device){
  	//since click happens before change assume status is reverse
  	if(!device.isChecked)
  		this.selectedBeacons.push(this.int8ArrayToString(device.bid));
  	else
      this.selectedBeacons.splice(this.selectedBeacons.indexOf(this.int8ArrayToString(device.bid)),1);
  }

  closeModal() {
    this.modalCtrl.dismiss({
        'dismissed':true,
        'selectedBeacons':this.selectedBeacons
      });
  }

  stopBeaconScan(){
    this.isScanning = false;
    this.window.evothings.eddystone.stopScan(()=>{
      console.log("Scan Stopped!");
    })
  }

  startScanningForBeacons(){
  	var that = this;
    this.platform.ready().then(() => {
      if (this.platform.is('cordova')) {
        setTimeout(()=>{
          this.window.evothings.eddystone.stopScan(()=>{
            console.log("Scan stopped!");
          })
        },10000);
        this.isScanning = true;
        this.window.evothings.eddystone.startScan((device) => {
          if(device.hasOwnProperty('bid')){
              if(that.devices.indexOf(device) == -1){
              	device['isChecked'] = false;
              	if(this.selectedBeacons.indexOf(this.int8ArrayToString(device.bid)) != -1)
              		device.isChecked = true;
              	that.devices.push(device);
              }
              this.zone.run(() => {console.log(that.devices)});
          }
        },
        error =>console.error('error: ', error))
      }
    });
  }

  int8ArrayToString(uint8array){
    return this.util.uint8ArrayToString(uint8array)
  }

  assignBeacon(){
    this.quizService.sectionAllowedBeacons(this.sectionId, this.selectedBeacons).subscribe((resp:any) =>{
        this.util.showMessage(resp);
        if(resp.success){
            this.section.beacons = resp.beacons;
            this.stopBeaconScan();
        }
    })
  }

}
