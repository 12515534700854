import { Injectable, NgZone } from '@angular/core';
import { SocketIoModule, SocketIoConfig, Socket } from 'ng6-socket-io';
import * as io from 'socket.io-client';
import { UtilService } from './util.service';
import { Storage } from '@ionic/storage';
import { Observable,timer } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SocketService { // extends Socket {
    socket;
    util;
    constructor(util:UtilService,ngZone: NgZone, storage: Storage) {
      this.util = util;
      let token = util.getStorage(util.authKey);
      // console.log('token: ', token)
      token.then(token=>{
        if(token){
          token = token.replace(/\"/g,"");
          this.connectToServer({token})
        }
      })     
    }
    
    connectToServer(query){
      let domain = this.util.url;
      this.socket = io(domain, {query});
    }
    
    sendMessage(msg: string){
        this.socket.emit("message", msg);
    }
    
    getMessage() {
        return this._eventlisten("message");
    }

    _eventlisten(type){
      return new Observable((subscriber)=>{
        var connection_interval = setInterval(()=>{
          if(this.socket){
            clearInterval(connection_interval);
            this.socket.on(type,(data:any)=>{
              subscriber.next(data);
            })
          }
        },1000)
      })
    }

    sessionUpdated(){
      return this._eventlisten("update_session")
    }

    newSurvey(){
      return this._eventlisten("new_survey")
    }

    notification(){
      return this._eventlisten("new_notification")
    }

    termUpdate(){
      return this._eventlisten("term_updated")
    }

    quizUpdated(){
      return this._eventlisten("quiz_updated")
    }

    runningQuizUpdate(){
      return this._eventlisten("running_quiz_update")
    }

    quizStopped(){
      return this._eventlisten("quiz_stopped")
    }
}
