import { Component, OnInit } from '@angular/core';
import { NavParams, AlertController, PopoverController } from '@ionic/angular';
import { AuthenticationService, User } from './../../services/authentication.service';
import { UtilService } from './../../services/util.service';
import { TermService } from './../../services/term.service';
import { Router, RouterEvent } from '@angular/router';
import { SocketService } from './../../services/socket.service';
import { ModalController } from '@ionic/angular';
import { ShareTermComponent } from './../share-term/share-term.component';
import { TermReportComponent } from '../term-report/term-report.component';

@Component({
	selector: 'app-term-popover',
	templateUrl: './term-popover.component.html',
	styleUrls: ['./term-popover.component.scss'],
})

export class TermPopoverComponent implements OnInit {
	term:any;
	constructor(
		private router: Router, 
		private popoverCtrl: PopoverController, 
		private authService: AuthenticationService,
		private navParams: NavParams,
		private util: UtilService,
		private termService: TermService,
		private alertController: AlertController,
		private socket: SocketService,
		private modalController: ModalController
	) { }

	ngOnInit() {
		this.term = this.navParams.get('term');
		this.socket.termUpdate().subscribe((data:any)=>{
			if(data && data.term_id && this.term._id == data.term_id){
				this.term[data.field.key] = data.field.value;
			}
		});
	}

	async onDismiss() {
		try {
			await this.popoverCtrl.dismiss();
		} catch (e) {
			// click more than one time popover throws error, so ignore...
		}
	}

	editNameAlert:any;

	async editName() {
		var that = this;
		that.editNameAlert = await this.alertController.create({
			'header': 'Change Name!',
			'subHeader': '',
			'inputs': [{
					'name':'termName',
					'value': this.term.name
				}],
			'buttons':[{
					'text':'Cancel',
					'role':'cancel',
					handler:data=>{}
					},{
						'text': 'Update',
						handler:data=>{
							
							this.util.showLoadingHandler("Updating Term!");
							this.termService.update(this.term._id,{'name':data.termName}).subscribe((resp:any)=>{
								this.util.hideLoadingHandler();
								this.util.showMessage(resp);
								if(resp.success){

									that.editNameAlert.dismiss();
								}
							})
							return false;
						}
					}]
				});
		await that.editNameAlert.present();
	}

	//   generateReport(){
	//   	this.util.showLoadingHandler("Generating Report!");
	//   	this.termService.report(this.term._id).subscribe((resp:any)=>{
	// 		this.util.hideLoadingHandler();
	// 		this.util.showMessage(resp);
	// 	})
	//   }

	async generateReport(){
		const modal = await this.modalController.create({
			component: TermReportComponent,
			componentProps: {'term':this.term}
		});
		await modal.present();
	}

	cloneTermAlert:any;
	async cloneTerm() {
		var that = this;
		that.cloneTermAlert = await this.alertController.create({
							'header': 'Clone Term!',
							'subHeader': '',
							'inputs': [{
									'name':'termName',
									'value': this.term.name+' Copy'
								}],
							'buttons':[{
									'text':'Cancel',
									'role':'cancel',
									handler:data=>{}
									},{
										'text': 'Clone',
										handler:data=>{
											
											this.util.showLoadingHandler("Cloning Term!");
											this.termService.clone(this.term._id,data.termName).subscribe((resp:any)=>{
												this.util.hideLoadingHandler();
												this.util.showMessage(resp);
												if(resp.success){
													this.router.navigateByUrl('/members/l/term/'+resp.term._id);
													that.cloneTermAlert.dismiss();
												}
											})
											return false;
										}
									}]
								});
		await that.cloneTermAlert.present();
	}

	changeStatus(){
		this.util.showLoadingHandler("Updating Term!");
		this.termService.updateStatus(this.term._id,this.term.status == 0?1:0).subscribe((resp:any)=>{
			this.util.hideLoadingHandler();
			this.util.showMessage(resp);
		})
	}


	async __shareTermModal(type){
		const modal = await this.modalController.create({
		component: ShareTermComponent,
		componentProps: {'term':this.term,'type':type}
		});

		await modal.present();

	}

	shareTerm(){
		this.__shareTermModal('share');
	}

	inviteCoTeachTerm(){
		this.__shareTermModal('invite');
	}

	// gotoPage(page) {
	//   this.router.navigate(['members', 'l', page], { replaceUrl: true });
	//   this.onDismiss();
	// }

}
