import { Component, OnInit, Input } from '@angular/core';
import { ModalController, AlertController } from '@ionic/angular';
import { QuizHelpComponent } from '../../quiz-help/quiz-help.component';
import { UtilService } from './../../../services/util.service';
import { TermService } from './../../../services/term.service';
import { QuizService } from './../../../services/quiz.service';
import { Router } from '@angular/router';
import { SocketService } from './../../../services/socket.service';

@Component({
  selector: 'app-edit-quiz',
  templateUrl: './edit-quiz.component.html',
  styleUrls: ['./edit-quiz.component.scss'],
})
export class EditQuizComponent implements OnInit {
  @Input() quiz: any;
  editQuiz: any;
  loadCompleted = false;
  terms: any;

  constructor(
    private util: UtilService,
  	private termService: TermService,
  	private quizService: QuizService,
    private modalController: ModalController,
    private alertController: AlertController,
    private router: Router,
    private socket: SocketService,
  ) { }

  ngOnInit() {
    if(this.quiz){
      this.editQuiz = this.util.clone(this.quiz);
      this.editQuiz.time_limit = this.editQuiz.time_limit/60;
      this.editQuiz.terms = this.editQuiz.terms[0]._id;
      this.editQuiz.questions = this.formatQuestions();
    }
    this._loadTerms();
    
    this.socket.quizUpdated().subscribe((data:any)=>{
      this.editQuiz = this.util.clone(data.quiz);
      this.editQuiz.time_limit = this.editQuiz.time_limit/60;
      this.editQuiz.terms = this.editQuiz.terms[0]._id;
      this.editQuiz.questions = this.formatQuestions();
    })
  }

  _loadTerms(){
    this.util.showLoadingHandler("Loading data!");
  	this.termService.getTermswithFields(null,['name']).subscribe((resp:any)=>{
      this.util.hideLoadingHandler();
  		this.loadCompleted = true;
  		if(resp.terms){
  			this.terms = resp.terms;
      }
  	})
  }

  async openHelpPopover(){
  	const modal = await this.modalController.create({
      component: QuizHelpComponent
    });
    return await modal.present();
  }

  checkSelection(){
  	if(this.editQuiz.terms == 'createterm'){
  		this.editQuiz.terms = null;
  		this._createTerm();
  	}
  }

  createTerm:any;
  async _createTerm(){
  	var that = this;
  	that.createTerm = await this.alertController.create({
					      'header': 'Create Term!',
					      'subHeader': '',
					      'inputs': [{
					        	'name':'termName',
					        	'value': ''
					        }],
					      'buttons':[{
					        	'text':'Cancel',
					        	'role':'cancel',
					        	handler:data=>{
					        		that.quiz.term = null;
					        	}
					            },{
					            	'text': 'Update',
					            	handler:data=>{
					            		
					            		that.util.showLoadingHandler("Creating Term!");
					            		that.termService.create(data.termName).subscribe((resp:any)=>{
					            			that.util.hideLoadingHandler();
					            			that.util.showMessage(resp);
					            			if(resp.success){
					            				let new_term = {
					            					'name': resp.term.name,
					            					'_id': resp.term._id
					            				};
					            				that.terms.push(new_term);
					            				that.quiz.terms = new_term._id;
					            				that.createTerm.dismiss();
					            			}
					            		})
					            		return false;
					            	}
					            }]
						    });
  	await that.createTerm.present();
  }

  updateQuiz(){
    if (this.util.isObjectEmpty(this.editQuiz.terms)) {
      this.util.showError( "Please select a term");
      return;
    }
    if (!this.editQuiz.time_limit) {
      this.util.showError( "Please specify the time limit in minutes.");
      return;
    }

    if (!this.editQuiz.send_question_limit) {
        this.util.showError( "Please specify the number of questions.");
        return;
    }
    this.editQuiz.time_limit = this.editQuiz.time_limit*60;

  	this.quizService.updateQuizNoQuestions(this.editQuiz)
        .subscribe((resp:any)=>{
        	this.util.showMessage(resp);
          if (resp.success) {
            // this.router.navigateByUrl('members/l/quiz/'+resp.quiz._id,{state:{'quiz':resp.quiz}});
            this.editQuiz = resp.quiz;
            this.editQuiz.time_limit = this.editQuiz.time_limit/60;
            // this.router.navigateByUrl('members/l/quiz/'+resp.quiz._id);
          }

        })
  }

  formatQuestions(){
    var formattedQuestions = [];
    for (var i = 0, length = this.editQuiz.questions.length; i < length; i++) {
        var formattedQuestion = {
          question_title : this.editQuiz.questions[i].question,
          html : this.editQuiz.questions[i].html,
          correct_answer : this.editQuiz.questions[i].correct_answer.text,
          _id : this.editQuiz.questions[i]._id,
          options : [],
          expand: false
        };
        for (var j = 0, j_length = this.editQuiz.questions[i].options.length; j < j_length; j++) {
            formattedQuestion.options.push(this.editQuiz.questions[i].options[j].text);
        }
        formattedQuestions.push(formattedQuestion);
    }
    return formattedQuestions;
  }

}
