import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { UtilService } from './util.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private _http: HttpClient,
    private util: UtilService
  ) { }

  loginUser(email, password) {
    // console.log(this.util.domain());
    return this._http.post(this.util.url + '/api/authenticate',  { email, password,'type':'mobile','pushID':this.util.getStorage(this.util.pushID)});
  }

  isThatYou(){
    return this._http.get(this.util.url+ '/api/is-this-you');
  }

  getUser(id?: string) {
    let url = this.util.url + '/api/users';
    if (!this.util.isObjectEmpty(id)) {
      url += '/' + id;
    }
    return this._http.get(url);
  }

  getUserWithToken(token){
    return this._http.get(this.util.url + '/api/users?token='+token);
  }

  getCreator(id){
    return this._http.get(this.util.url + '/api/users/creator/' + id)
  }

  searchUsers(query){
    return this._http.get(this.util.url + '/api/users/autocomplete/' + query)
  }

  checkDomain(email) {
    return this._http.get(this.util.url + '/api/checkdomain/desktop/' + email);
  }

  checkDomainSignUp(email) {
    return this._http.get(this.util.url + '/api/checkdomain/desktop/' + email + '/signup');
  }

  register(data){
    return this._http.post(this.util.url + '/api/register', data);
  }

  activate(data) {
    return this._http.post(this.util.url + '/api/register/activate', data);
  }

  changeName(data) {
    return this._http.put(this.util.url + '/api/users', data);
  }

  resendActivateCode(data) {
    return this._http.post(this.util.url + '/api/register/resend', data);
  }

  authUserCheck(authCode) {
    return this._http.post(this.util.url + '/api/authuser/' + authCode, {'pushID':this.util.getStorage(this.util.pushID)});
  }

  forgetPassword(email) {
    return this._http.post(this.util.url + '/api/forgetpassword', {'email': email});
  }

  resetPassword(data) {
    return this._http.post(this.util.url + '/api/forgetpassword/reset', data);
  }

  uploadAvatar(user_id, img) {
    return this._http.post(this.util.url + '/api/users/upload_avatar/' + user_id, {'avatar': img});
  }

  updatePassword(data) {
    return this._http.put(this.util.url + '/api/users', data);
  }

  settings() {
    // return this._http.get(this.util.url + '/api/users/myoptions');
    return this._http.get(this.util.url + '/api/notifications');
  }

  // saveSettings(id, metaKey, enabled) {
  saveSettings(email, notification) {
    // if (!this.util.isObjectEmpty(id) && !this.util.isObjectEmpty(metaKey)) {
    //   const link = enabled ? 'subscribe' : 'unsubscribe';
    //   return this._http.get(this.util.url + '/api/' + link + '/' + id + '/' + metaKey);
    // }
    return this._http.put(this.util.url + '/api/notifications', {email: email, notification: notification});
  }

  sendMessage(data) {
    return this._http.post(this.util.url + '/api/users/send_notification', data);
  }

  updateBeacon(data){
    return this._http.put(this.util.url + '/api/users/add-beacon',data);
  }

  studentQuizzesReport(user_id,term_id?){
    var url = this.util.url + '/api/generate_report/student/id='+user_id+'&term='
    if(term_id)
      url+=term_id;
    return this._http.get(url)
  }

  studentQuizReport(user_id,section_id){
    return this._http.get(this.util.url + '/api-v2/generate_report/student/'+user_id+'/quiz/'+section_id)
  }

  myQuizReport(quiz_id?){
    if(quiz_id)
      return this._http.get(this.util.url+'/api-v2/generate_report/student-quiz/'+quiz_id);
    else  
      return this._http.get(this.util.url+'/api-v2/generate_report/my-quiz-reports');
  }

  myLastQuizzes(){
    return this._http.get(this.util.url+'/api-v2/generate_report/my-last-quizzes');
  }

  query(query,page){
    return this._http.get(this.util.url+'/api/generate_report/student/query='+query+'&'+page);
  }
}
