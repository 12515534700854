import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ToastController } from '@ionic/angular';
import { UserService } from 'src/app/services/user.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { InstructorService } from 'src/app/services/instructor.service';
import { UtilService } from 'src/app/services/util.service';

@Component({
  selector: 'app-send-notification',
  templateUrl: './send-notification.component.html',
  styleUrls: ['./send-notification.component.scss'],
})
export class SendNotificationComponent implements OnInit {
  classes: any;
  notification = {
    message: null,
    header: null
  }
  user: any;
  charsLeft: any = 119;
  courses: any;
  page = 0;
  filters :any = {
  	course:'',
  	semester:'',
  	year:''
  };
  cterms = {};

  constructor(
    private modalController: ModalController,
    public toastController: ToastController,
    private userService: UserService,
    private authService: AuthenticationService,
    private instructorService: InstructorService,
    private util: UtilService
  ) { }

  ngOnInit() {
    this.authService.currentUser.subscribe(user => {
      this.user = user;
    });

    this.loadClasses();
  }

  async closeModal() {
    await this.modalController.dismiss();
  }

  loadClasses() {
    this.instructorService.getCurrentClasses(this.page, this.filters).subscribe((resp: any) => {
      // console.log(resp);

      if(resp && resp.success) {
        // this.courses = resp.classes;

        if(this.page == 0)
        		this.courses = [];
        	
        resp.data.classes.forEach(c =>{
          this.cterms[c._id] = {
            semester: c.tags.semester[0].name,
            year: c.tags.year[0].name
          };
          this.courses.push(c); 
        });

        var num_page = Math.floor(resp.total/resp.limit);
        if (resp.total > resp.limit || num_page != this.page + 1) {
          this.page++;
        }

        // console.log(this.courses);
      } else {
        this.presentToast('You don\'t have any classes', 'danger');
      }
    })
  }

  async sendMessage() {
    // console.log("instructor id: ");
    // console.log(this.user);

    if (this.notification.message && this.notification.message.length <= 119 && this.notification.header && this.classes) {
      // tslint:disable-next-line: max-line-length
      this.userService.sendMessage({
        classes: this.classes,
        user: this.user,
        notification: this.notification,
      }).subscribe((resp: any) => {
        this.util.showMessage(resp);
      });
      await this.modalController.dismiss();
    } else {
      this.presentToast('You must fill up all the form to send, and the message can not have more than 119 characters', 'danger');
    }
  }

  async presentToast(message: string, status: string) {
    const toast = await this.toastController.create({
      message,
      duration: 2000,
      animated: true,
      color: status
    });
    toast.present();
  }

  countDownChars() {
    if(this.notification.message){
      this.charsLeft = 119 - this.notification.message.length;
    }
  }

}
