import { Injectable, SecurityContext } from '@angular/core';
// import { NotificationService } from './notification.service';
import { ToastController } from '@ionic/angular';
import { Platform, LoadingController,  } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { AlertController } from '@ionic/angular';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class UtilService {
	url: string = 'https://app.quizatclass.com';
	// url: string = 'https://gbc.localhost';
	// url: string = 'https://192.168.2.21';
	// url: string = 'http://192.168.2.21:3000';
	// url: string = 'http://192.168.2.14:3000';
	// url: string = 'http://192.168.2.12:3000';
	// url: string = 'http://192.168.2.13:3000';
	// url: string = 'https://192.168.2.14';
	// url: string = 'http://192.168.43.34';
	// url: string = 'http://192.168.0.102';
	// url: string = 'http://192.168.1.12';
	// url: string = 'http://demo.quizatclass.com';
	// url:string = 'http://localhost';
	window: any;
	adfsUrl: string = 'https://gbc-auth.atklass.com/';
	loader: any = null;
	authCode : string = 'authCode';
	authKey: string = 'auth_token';
	optionsKey: string = 'options';
	sessionTempQuiz: string = 'sesstion_temp_code';
	pushID: string = 'pushID';
	userConfirmed : string = 'userConfirmed';
	tempSectionCode: string = 'tempSectionCode';
	
	roles: {
		INSTRUCTOR: 'instructor',
		MEMBER: 'member',
		STUDENT: 'student',
		MANAGER: 'manager',
		ADMIN: 'admin'
	}


	categories:{
		COURSE: 'course',
		SEMESTER : 'semester',
		YEAR: 'year'
	}

	constructor(
		private storage: Storage, 
	  	public loadingController: LoadingController,
		// private notificationService: NotificationService,
		public toastController: ToastController,
		public alertController: AlertController,
		private _sanitizer: DomSanitizer
	 ) { 
	}

	Sanitize = {
		encodeHTML(text){
			var map = {
		        "&": "&amp;",
		        "<": "&lt;",
		        ">": "&gt;",
		        "\"": "&quot;",
		        "'": "&#39;" // ' -> &apos; for XML only
		    };
		    return text.replace(/[&<>"']/g, function(m) { return map[m]; });
		},
		decodeHTML(text){
			var map = {
		        "&amp;": "&",
		        "&lt;": "<",
		        "&gt;": ">",
		        "&quot;": "\"",
		        "&#39;": "'"
		    };
		    return text.replace(/(&amp;|&lt;|&gt;|&quot;|&#39;)/g, function(m) { return map[m]; });
		},
		encodeJS(text){
			var that = this;
			var re = /(<script\b[^>]*>)([\s\S]*?)(<\/script>)/gm;

			var match;
			while (match = re.exec(text)) {
			  if(match[0]){
			  	text = text.replace(match[1],that.encodeHTML(match[1]));
			  	text = text.replace(match[3],that.encodeHTML(match[3]));
			  }
			}
			text = that.encodeCode(text);
			return text;
		},
		encodeCode(text){
			var that = this;
			var re = /(<code\b[^>]*>)([\s\S]*?)(<\/code>)/gm;

			var match;
			while (match = re.exec(text)) {
			  if(match[0]){
			  	// text = text.replace(match[1],that.encodeHTML(match[1]));
			  	text = text.replace(match[2],that.encodeHTML(match[2]));
			  	// text = text.replace(match[3],that.encodeHTML(match[3]));
			  }
			}
			return text;
		}
	}

	uint8ArrayToString(uint8Array){
		function format(x)
		{
		var hex = x.toString(16);
		return hex.length < 2 ? '0' + hex : hex;
		}

		var result = '';
		for (var i = 0; i < uint8Array.length; ++i)
		{
		result += format(uint8Array[i]);
		}
		return result;
	}

	stringToBytes(string) {
		var array = new Uint8Array(string.length);
		for (var i = 0, l = string.length; i < l; i++) {
			array[i] = string.charCodeAt(i);
		}
		return array.buffer;
	}

	bytesToString(buffer) {
		return String.fromCharCode.apply(null, new Uint8Array(buffer));
	}

	objectToArrayNoKey(obj){
		return Object.values(obj);
	}

	setStorage(key: string, data: any) {
	    try {
	      // localStorage.setItem(key, JSON.stringify(data));
	      return this.storage.set(key, JSON.stringify(data));
	    } catch (e) {
	      console.error('Error saving to localStorage', e);
	    }
	}

	parseJSON(text: string){
		return JSON.parse(text);
	}

	getStorage(key: string) {
	    try {
	      // return JSON.parse(localStorage.getItem(key));
	      return this.storage.get(key);
	    } catch (e) {
	      console.error('Error getting data from localStorage', e);
	      return null;
	    }
	}



	removeStorage(key: string){
		try{
			// localStorage.removeItem(key);
			return this.storage.remove(key);
		}catch(e){
			console.log("Error removing storageitem", e);
		}
	}

	isCSV(url){
	   if (url)
	   {
	      var m = url.toString().match(/^.*\.(csv)$/i);
	      if (m && m.length > 1)
	      {
	         return true;
	      }
	   }
	   return false;
	}

	getDateFromUTC = function(a_date, addOffsetInMinutes) {

		if(typeof a_date != 'object')
			a_date = new Date(a_date);

		if(!this.isObjectEmpty(addOffsetInMinutes))
			return new Date(Date.UTC(a_date.getUTCFullYear(), a_date.getUTCMonth(), a_date.getUTCDate(), a_date.getUTCHours(), a_date.getUTCMinutes() + addOffsetInMinutes, a_date.getUTCSeconds()));

		return new Date(Date.UTC(a_date.getUTCFullYear(), a_date.getUTCMonth(), a_date.getUTCDate(), a_date.getUTCHours(), a_date.getUTCMinutes(), a_date.getUTCSeconds()));
	}

	async showLoadingHandler(msg?:string) {
		msg = msg || "Loading!";
	    if (this.loader == null) {
	        this.loader = await this.loadingController.create({
	            message: msg,
	            duration: 3000,
	            // backdropDismiss: true,
	            translucent: true,
	            showBackdrop: false,
	            spinner: 'crescent'
	        });
	        this.loader.present();
	    } else {
	    	if(this.loader && this.loader.hasOwnProperty('data')){
	        	this.loader.data.message = msg;
	    	}else{
	        	this.loader = await this.loadingController.create({
		            message: msg,
		            duration: 5000,
		            // backdropDismiss: true,
		            translucent: true,
		            showBackdrop: false,
		            spinner: 'crescent'
		        });
		        this.loader.present();
	        }
	    }
	}

	hideLoadingHandler() {
		if (this.loader != null) {
		    this.loader.dismiss();
		    this.loader = null;
		}
	}


  	domain(){
		return this.url;
	}

	getDomain(url){
        var matches = url.match(/^https?\:\/\/([^\/?#]+)(?:[\/?#]|$)/i);
        return matches && matches[1];
    }

	isFunction(object){
		if(typeof object === "undefined")
	        return false;

	    if(typeof object === "function")
	        return true;
	    else
	    	return false;
	}

	isEmail(string){
	    var re = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/
	    // /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
	    return re.test(string);
	}

	isObject(obj){
		return obj === Object(obj);
	}

	isObjectEmpty(object) {
	    if(typeof object === "undefined")
	        return true;

	    if(object === null)
	        return true;

	    if(typeof object == 'object') {
			if(object instanceof Date)
				return false;
			else {
				if(object.length == undefined) {
					if((Object.keys(object)).length === 0)
						return true;
				}
				else {
					if(object.length === 0)
						return true;
				}
			}
		}

	    if(typeof object === 'string') {
	        // if (this.fixstring(object).length === 0)
	        if(object.trim().length == 0)
	            return true;
	    }

	    return false;
	}

	/*
		buttons = [{
              'text':'Cancel',
              handler:()=>{
                
              }
            },{
              'text':'Continue',
              handler:()=>{
                
              }
            }]
	*/
	async confirmAlert(header,subheader,message,buttons) {
		header = header || '';
		subheader = subheader || '';
		message = message || '';

	    const alert = await this.alertController.create({
	      'header': header,
	      'subHeader': subheader,
	      'message': message,
	      'buttons': buttons
	    });

	    await alert.present();
	  }

	async promptAlert(header,subheader,inputs, buttons) {
		header = header || '';
		subheader = subheader || '';
		const alert = await this.alertController.create({
	      'header': header,
	      'subHeader': subheader,
	      'inputs': inputs,
	      'buttons': buttons
	    });

	    await alert.present();
	} 

	showMessage(opts: object){
		if(this.isObjectEmpty(opts)){
			this.showError("Couldn't get a response, please make sure that you are connected to the internet or contact a site administrator.");
		}else{
			var options = Object.assign({success:false, msg: '', message: '', data: { success: false, msg: '', message: ''}},opts);
			var type: any = options.success || (!this.isObjectEmpty(options.data) ? options.data.success : false);
			var msg: any = options.msg || options.message || (!this.isObjectEmpty(options.data) ? options.data.msg : false) || (!this.isObjectEmpty(options.data) ? options.data.message : false);

			switch (type) {
				case true:
				case 'success':
					this.showSuccess(msg);
					break;
				case false:
				case 'error':
					this.showError(msg);
					break;
				case 'warn':
					this.showWarning(msg);
					break;
				case 'info':
					this.showInfo(msg);
					break;
			}
		}
	}

	showSuccess(message: string, opts: object = {}) {
		this.presentToast(message, 'success');
	}

	showError(message: string, opts: object = {}) {
		this.presentToast(message, 'danger');
	}

	showInfo(message: string, opts: object = {}) {
		this.presentToast(message, 'secondary');
	}

	showWarning(message: string, opts: object = {}) {
		this.presentToast(message, 'warning');
	}

	async presentToast(message: string, status: string) {
		const toast = await this.toastController.create({
		  message,
		  duration: 2000,
		  animated: true,
		  color: status,
		  position: 'top'
		});
		toast.present();
	}

	// clearMessage() {
	//     this.notificationService.clear();
	// }


	clone(obj){
		return JSON.parse(JSON.stringify(obj));
	}
	
	getRandomInt(min, max) {
	  min = Math.ceil(min);
	  max = Math.floor(max);
	  return Math.floor(Math.random() * (max - min)) + min; //The maximum is exclusive and the minimum is inclusive
	}

	getRandomIntInclusive(min, max) {
	  min = Math.ceil(min);
	  max = Math.floor(max);
	  return Math.floor(Math.random() * (max - min + 1)) + min; //The maximum is inclusive and the minimum is inclusive
	}

	downloadFile(buffer, fileName) {
		try {
		  const blob = new Blob([buffer], {
		    type: 'application/zip'
		  });
		  const url = URL.createObjectURL(blob);
		  const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
		  a.href = url;
		  a.download = fileName;
		  document.body.appendChild(a);
		  a.click();
		  document.body.removeChild(a);
		  URL.revokeObjectURL(url);
		} catch (error) {
		  this.showError("Failed to download the file.");
		}
	}

	fixstring(str) {
		/*
		 * Trims and removes extra column
		 * String examples
		 * INPUT:  "   AAA A    AAAA  "
		 * OUTPUT: "AAA A AAAA"
		 * INPUT:  "AAAAA   AAA  "
		 * OUTPUT: "AAAAA AAA"
		 *
		 */

		return str.replace(/(^\s*)|(\s*$)/gi, "").replace(/\s+/gi, " ");
	}

	fixName(str) {
		return str.replace(/^\w/, c => c.toUpperCase());
	}

	localStorage = {
		add : function(key, value){
			window.localStorage.setItem(key,JSON.stringify(value));
		},
		get : function(key){
			var value = '';
			try {
				window.localStorage.getItem(key);
			}catch(err) {
				window.localStorage.getItem(key);
		    }
			return value;
		},
		remove : function(key){
		    window.localStorage.removeItem(key)
		},
		clear : function(key){
		    window.localStorage.clear()
		}
	}

	sessionStorage = {
		add : function(key, value){
			window.sessionStorage.setItem(key,JSON.stringify(value));
		},
		get : function(key){
			var value = '';
			try {
				value = window.sessionStorage.getItem(key);
			}catch(err) {
				value = window.sessionStorage.getItem(key);
		    }
			return value;
		},
		remove : function(key){
		    window.sessionStorage.removeItem(key)
		},
		clear : function(key){
		    window.sessionStorage.clear()
		}
	}

	byteString11bit =  function(n) {
		return ("000000000000" + n.toString(2)).substr(-11)
	}


	isArray(obj){
		return Array.isArray(obj);
	}

	passwordStrength(pwd){
		var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})");
		var mediumRegex = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})");

		if(strongRegex.test(pwd)) {
            return 2;
        } else if(mediumRegex.test(pwd)) {
            return 1;
        } else {
            return 0;
        }
	}


  	htmlProperty(html_content) : SafeHtml {
     	return this._sanitizer.sanitize(SecurityContext.HTML, html_content);
  	}

  	shuffle(array) {
        var counter = array.length, index, temp;

        // While there are elements in the array
        while (counter > 0) {
            // Pick a random index
            index = Math.floor(Math.random() * counter--);

            // Decrease counter by 1

            // And swap the last element with it
            temp = array[counter];
            array[counter] = array[index];
            array[index] = temp;
        }
    }

    timestampToTime(timestamp){
		timestamp = Math.abs(this.clone(timestamp));
		var days = Math.floor(timestamp / (1000 * 3600 * 24));
		if(days)
			timestamp -= days * (1000 * 3600 * 24);
		var hours = Math.floor(timestamp / (1000 * 3600));
		if(hours)
			timestamp -= hours * (1000 * 3600);
		var minutes = Math.floor(timestamp / (1000 * 60));
		if(minutes)
			timestamp -= minutes * (1000 * 60);
		var seconds = Math.floor(timestamp / 1000);

		var html = '';

		if(days>0)
			html += days + ' days ';

		if(hours.toString().length == 1)
			html += '0'+hours+':';
		else
			html += hours+':';

		if(minutes.toString().length == 1)
			html += '0'+minutes+':';
		else
			html += minutes+':';

		if(seconds.toString().length == 1)
			html += '0'+seconds;
		else
			html += seconds;
		return html;
	}


	CSVToArray(strData, strDelimiter?) {
	    // Check to see if the delimiter is defined. If not,
	    // then default to comma.
	    strDelimiter = (strDelimiter || ",");
	    // Create a regular expression to parse the CSV values.
	    var objPattern = new RegExp((
	    // Delimiters.
	    "(\\" + strDelimiter + "|\\r?\\n|\\r|^)" +
	    // Quoted fields.
	    "(?:\"([^\"]*(?:\"\"[^\"]*)*)\"|" +
	    // Standard fields.
	    "([^\"\\" + strDelimiter + "\\r\\n]*))"), "gi");
	    // Create an array to hold our data. Give the array
	    // a default empty first row.
	    var arrData = [[]];
	    // Create an array to hold our individual pattern
	    // matching groups.
	    var arrMatches = null;
	    // Keep looping over the regular expression matches
	    // until we can no longer find a match.
	    while (arrMatches = objPattern.exec(strData)) {
	        // Get the delimiter that was found.
	        var strMatchedDelimiter = arrMatches[1];
	        // Check to see if the given delimiter has a length
	        // (is not the start of string) and if it matches
	        // field delimiter. If id does not, then we know
	        // that this delimiter is a row delimiter.
	        if (strMatchedDelimiter.length && (strMatchedDelimiter != strDelimiter)) {
	            // Since we have reached a new row of data,
	            // add an empty row to our data array.
	            arrData.push([]);
	        }
	        // Now that we have our delimiter out of the way,
	        // let's check to see which kind of value we
	        // captured (quoted or unquoted).
	        if (arrMatches[2]) {

	            // We found a quoted value. When we capture
	            // this value, unescape any double quotes.
	            var strMatchedValue = arrMatches[2].replace(
	            new RegExp("\"\"", "g"), "\"");
	        } else {
	            // We found a non-quoted value.
	            var strMatchedValue = arrMatches[3];
	        }
	        // Now that we have our value string, let's add
	        // it to the data array.
	        arrData[arrData.length - 1].push(strMatchedValue);
	    }
	    // Return the parsed data.
	    return (arrData);
	}
		
	CSV2JSON(csv) {
		try{
		    var array = this.CSVToArray(csv.trim());
		    var objArray = [];

		    for (var i = 1; i < array.length; i++) {
		        objArray[i - 1] = {};
		        for (var k = 0; k < array[0].length && k < array[i].length; k++) {
		        	if(this.isObjectEmpty(array[i][k]))
		        		console.log(array[i][k])
		        	if(!this.isObjectEmpty(array[i][k])){
			            var key = array[0][k];
			            objArray[i - 1][key] = array[i][k]
			        }
		        }
		        // if(Object.keys(objArray[i - 1]).length<4){
		        // 	delete objArray[i - 1];
		        // }
		    }
		    var json = JSON.stringify(objArray);
		    //var str = json.replace(/},/g, "},\r\n");
		    var result = JSON.parse(json);

		    return result;
		}catch(ex){
			return false;
		}
	}

	convertToMark(percentage){
		if(typeof percentage == 'number'){
			if(percentage >= 90) return "A+";
			else if(percentage >= 86) return "A";
			else if(percentage >= 80) return "A-";
			else if(percentage >= 77) return "B+";
			else if(percentage >= 73) return "B";
			else if(percentage >= 70) return "B-";
			else if(percentage >= 67) return "C+";
			else if(percentage >= 63) return "C";
			else if(percentage >= 60) return "C-";
			else if(percentage >= 57) return "D+";
			else if(percentage >= 50) return "D";
			else if(percentage >= 0) return "F";
		} else {
			return ""
		}
	}

	slugify(text){
	  return text.toString().toLowerCase()
	    .replace(/\s+/g, '-')           // Replace spaces with -
	    .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
	    .replace(/\-\-+/g, '-')         // Replace multiple - with single -
	    .replace(/^-+/, '')             // Trim - from start of text
	    .replace(/-+$/, '');            // Trim - from end of text
	}
    
}
