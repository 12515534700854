import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sort'
})
export class SortPipe implements PipeTransform {

  transform(array: Array<string>, args?: any): Array<string> {
    return array.sort(function(a, b){
      var item1 = a[args.property];
      var item2 = b[args.property];
      
      if(item1 < item2){
          return -1 * args.order;
      }
      else if( item1 > item2){
          return 1 * args.order;
      }
      else{
          return 0;
      }
    });
  }

}
