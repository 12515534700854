import { Component, ViewChild, OnInit, Input, ElementRef } from '@angular/core';
import { UtilService } from './../../services/util.service';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-download-app',
  templateUrl: './download-app.component.html',
  styleUrls: ['./download-app.component.scss'],
})
export class DownloadAppComponent implements OnInit {
  @Input() platform: any;
  @Input() platform_url: any;

  constructor(
  	private utill: UtilService,
  	private modalCtrl: ModalController,
  ) { }

  ngOnInit() {}


  closeModal() {
    this.modalCtrl.dismiss({
      'dismissed':true
    });
  }

}
