import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { UtilService } from './util.service';

@Injectable({
  providedIn: 'root'
})
export class SharedobjsService {

  constructor(
    private _http: HttpClient,
    private util: UtilService
  ) { }

  shareObj(data){
    return this._http.post(this.util.url + '/api/sharedobjs/share', data);
  }

  shareObjTo(data){
    return this._http.post(this.util.url + '/api/sharedobjs/share_to', data);
  }

  saveObj(data){
    return this._http.post(this.util.url + '/api/sharedobjs', data);
  }

  sharedWithMe(){
    return this._http.get(this.util.url+'/api/sharedobjs/shared_with_me');
  }

  sharedByMe(){
    return this._http.get(this.util.url+'/api/sharedobjs/shared_by_me');
  }

  sharedCoTeaching(){
    return this._http.get(this.util.url+'/api/sharedobjs/shared_co_teaching');
  }

  sharedCoTeachingQuiz(quiz_id){
    return this._http.get(this.util.url+'/api/sharedobjs/shared_co_teaching/' + quiz_id);
  }

  acceptSharedCoTeaching(data){
    return this._http.post(this.util.url+'/api/sharedobjs/shared_co_teaching', data);
  }

  getSharedObjs(id){
    return this._http.get(this.util.url+'/api/sharedobjs/get_shared_objs/' + id);
  }

  deleteShareFromList(id){
    return this._http.delete(this.util.url+'/api/sharedobjs/reject/' + id);
  }

  deleteSharedObj(id){
    return this._http.delete(this.util.url+'/api/sharedobjs/' + id);
  }


}
