import { Component, OnInit, ViewChild } from '@angular/core';
import { NavController, ModalController, ActionSheetController, Events } from '@ionic/angular';
import { AuthenticationService, User } from './../../../services/authentication.service';
import { UserService } from './../../../services/user.service';
import { Camera, CameraOptions } from '@ionic-native/camera/ngx';
import { File } from '@ionic-native/file/ngx';
import { ImageCropperComponent, CropperSettings } from 'ngx-img-cropper';
import { UtilService } from './../../../services/util.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable } from 'rxjs';



@Component({
  selector: 'app-update-image',
  templateUrl: './update-image.component.html',
  styleUrls: ['./update-image.component.scss'],
})

export class UpdateImageComponent implements OnInit {
  user: any;
  data: any;
  cropperSettings: CropperSettings;
  @ViewChild('cropper', undefined) cropper: ImageCropperComponent;

  constructor(
    private util: UtilService,
    private authService: AuthenticationService,
    private userService: UserService,
    private camera: Camera,
    private nav: NavController,
    private modalCtrl: ModalController,
    public actionSheetController: ActionSheetController,
    private file: File,
    private sanitizer: DomSanitizer,
    private events: Events
  ) { }

  ngOnInit() {
    this.cropperSettings = new CropperSettings();
    this.cropperSettings.width = 100;
    this.cropperSettings.height = 100;
    this.cropperSettings.croppedWidth = 200;
    this.cropperSettings.croppedHeight = 200;
    this.cropperSettings.canvasWidth = 300;
    this.cropperSettings.canvasHeight = 300;
    this.cropperSettings.noFileInput = true;
    this.cropperSettings.rounded = true;
    this.cropperSettings.cropperDrawSettings.strokeColor = 'rgba(255,255,255,1)';
    this.cropperSettings.cropperDrawSettings.strokeWidth = 2;
    this.cropperSettings.minWidth = 50;
    this.cropperSettings.minHeight = 50;

    this.data = {};

    this.authService.currentUser.subscribe(user => {
      this.user = user;
      if (this.user.avatar) {
        this.data.image = this.user.avatar.content;
      }
    });
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  pickImage(type: any) {
    const image: any = new Image();
    const options: CameraOptions = {
      quality: 75,
      destinationType: this.camera.DestinationType.DATA_URL,
      encodingType: this.camera.EncodingType.JPEG,
      mediaType: this.camera.MediaType.PICTURE,
      sourceType: type,
      targetWidth: 300,
      targetHeight: 300,
      correctOrientation: true,
    };

    this.camera.getPicture(options).then((imageData) => {
      image.src = 'data:image/jpeg;base64,' + imageData;
      this.data.image = image.src
    }, (err) => {
      console.log("Camera issue:" + err);
    });
  }

  getFromLibrary() {
    this.pickImage(this.camera.PictureSourceType.PHOTOLIBRARY);
  }

  captureImage() {
    this.pickImage(this.camera.PictureSourceType.CAMERA);
  }

  uploadAvatar() {
    if (this.data.image) {
      this.userService.uploadAvatar(this.user._id, this.data.image).subscribe((resp: any) => {
        this.util.showMessage(resp);
        if (resp && resp.success) {
          this.events.publish('avatarUpdated', {avatar: {content: this.data.image}});
          this.user.avatar.content = this.data.image;
        } else {
          console.log(resp.error);
        }
        this.modalCtrl.dismiss();
      });
    }
  }

}



// import { NgModule } from '@angular/core';
// import { CommonModule } from '@angular/common';
// import { FormsModule } from '@angular/forms';
// import { Routes, RouterModule } from '@angular/router';

// import { IonicModule } from '@ionic/angular';

// import { UpdateImagePage } from './update-image.page';
// import { SharedDirectivesModule } from './../../../directives/shared-directives.module';
// import { ImageCropperModule } from 'ngx-image-cropper';

// const routes: Routes = [
//   {
//     path: '',
//     component: UpdateImagePage
//   }
// ];

// @NgModule({
//   imports: [
//     CommonModule,
//     FormsModule,
//     IonicModule,
//     SharedDirectivesModule,
//     ImageCropperModule,
//     RouterModule.forChild(routes),
//   ],
//   declarations: [
//     UpdateImagePage,
//   ]
// })
// export class UpdateImagePageModule {}

