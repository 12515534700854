import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberSuffix'
})
export class NumberSuffixPipe implements PipeTransform {

  transform(value: number): string {
    var s=["th","st","nd","rd"],
       v=value%100;
   return value+(s[(v-20)%10]||s[v]||s[0]);
  }

}
