import { Component, OnInit, Input } from '@angular/core';
import { UtilService } from '../../services/util.service';
import { AnswerService } from '../../services/answer.service';
import { Router } from '@angular/router';
import { interval } from 'rxjs';
import { ModalController, ToastController } from '@ionic/angular';
import { SocketService } from '../../services/socket.service';
import { QuizService } from '../../services/quiz.service';

@Component({
  selector: 'app-start-quiz',
  templateUrl: './start-quiz.component.html',
  styleUrls: ['./start-quiz.component.scss'],
})
export class StartQuiz implements OnInit {
  @Input() quiz_data: any;
  @Input() questions: any;
  @Input() student_quiz_meta: any;
  @Input() preview = false;
  current_question :any;
  question_answered = false;
  correct_answer : any;
  total_questions = 0;
  completed_questions = 0;
  selected :any ;
  time_tracking_enabled = 0;
  timePassed :boolean;
  duration = 0;
  time_left = 0;
  number_of_questions_left = 0;
  server_datetime: any;

  constructor(
  	private utill : UtilService,
    private modalController: ModalController,
  	private router: Router,
  	private answerService: AnswerService,
    private socket: SocketService,
    private quizService: QuizService
  ) { }

  ngOnInit() {
  	if (!this.questions || this.utill.isObjectEmpty(this.quiz_data))
        this.closeQuestions();
    else {

        this.quizService.getServerDatetime().subscribe((resp:any) => {
          this.server_datetime = resp.server_datetime;

          this.current_question = false;
          this.question_answered = false;
          this.correct_answer = false;
          this.total_questions = this.quiz_data.number_of_question || 0;
          this.completed_questions = (this.total_questions - this.questions.length)+1;
            //(this.number_of_questions_left || 0))+1;
          this.selected = {};
          this.time_tracking_enabled = this.quiz_data.time_tracking_enabled
  
          // var now: any = Math.floor(new Date().getTime() / 1000)
          var now: any = Math.floor(new Date(this.server_datetime.toLocaleString("en-CA", {timeZone: "America/Toronto"})).getTime() / 1000)
          var time_limit: any = this.quiz_data.time_limit;
          var temp_time: any = new Date(this.student_quiz_meta.started_at.toLocaleString("en-CA", {timeZone: "America/Toronto"})).getTime() / 1000;
          // var temp_time: any = new Date(this.student_quiz_meta.started_at).getTime() / 1000;
          var q_time = Math.floor(temp_time);
          var delta : any = this.time_tracking_enabled ? (time_limit - (now - q_time)) : (now - q_time);
  
          this.timePassed = delta <= 0 || delta > time_limit
          // if (this.timePassed) { return }
  
          this.duration = time_limit
  
          var times = 0
          var timer = interval(1000).subscribe(()=>{
            times += 1
            // var now: any = Math.floor(new Date().getTime() / 1000)
            var now: any = Math.floor(new Date(this.server_datetime.toLocaleString("en-CA", {timeZone: "America/Toronto"})).getTime() / 1000)
            now += times
            var delta: any = this.time_tracking_enabled ? time_limit - (now - q_time) : now - q_time
            this.timePassed = delta <= 0 || delta > time_limit
            this.time_left = this.timePassed ? 0 : Math.abs(delta)
  
            if (this.timePassed) {
              this.time_left = 0
              if (this.time_tracking_enabled === 1) {
                timer.unsubscribe();
                timer = undefined
                this.closeQuestions(true)
              }
            }
          })
  
          this.time_left = this.timePassed ? 0 : delta
          this.nextQuestion(true)
        })
    }

    this.socket.quizStopped().subscribe((data:any)=>{
        if(data && data.section_id && this.quiz_data.section_id == data.section_id){
          this.closeModal();
          this.router.navigate(['members','l','quiz-result'],{state:{
              quiz_id: this.quiz_data.quiz_id,
              section_id: this.quiz_data.section_id
          }});
          this.utill.showInfo("Quiz stopped by creator!");
        }
    });
  }

  async closeModal() {
    await this.modalController.dismiss();
  }

  closeQuestions(timeDone?) {
    if(this.preview){
      this.closeModal();
    	this.router.navigate(['members','l','single_quiz'],{state:{'id': this.quiz_data.quiz_id}});
        return;
    }
    // $window.sessionStorage.remove('identification_id');
    // this.utill.sessionStorage.remove('section_code');
    this.utill.removeStorage(this.utill.tempSectionCode);
    // if($rootScope.hasOwnProperty('user'))
    //     $state.go('dashboard.takequiz')
    // else
    //     $state.go('attend');
    if (timeDone) {
      // this.utill.showError( 'You ran out of time to complete this quiz')
      this.utill.showInfo( 'If you did not finish this quiz, you ran out of time to complete it')
    }
    this.closeModal();
    this.router.navigate(['members','l','dashboard']);
  }

  getQuestion(question_id) {
    if (!question_id)
        return;
    this.utill.showLoadingHandler();
    this.answerService.getQuestion(question_id).subscribe((resp:any)=>{
        this.utill.hideLoadingHandler();
        if (resp.success) {
            this.question_answered = false;
            this.current_question = resp.question;
            this.utill.shuffle(this.current_question.options)
        } else {
            this.utill.showError(resp);
        }
    })
   }

   nextQuestion(initialCall?){
        if (!this.questions.length) {
            if(this.preview){
                // this.router.navigate(['members','l','single_quiz'],{state:{'id': this.quiz_data.quiz_id}});
                this.closeModal();
                return;
            }
            this.closeModal();
            this.router.navigate(['members','l','quiz-result'],{state:{
            	  quiz_id: this.quiz_data.quiz_id,
                section_id: this.quiz_data.section_id
            }});
        } else {
            if(!initialCall)
                this.completed_questions++;

            this.correct_answer = false;
            this.selected.option = null;
            if(this.preview){
                this.getQuestion(this.questions.shift());
            }else{
                this.getQuestion(this.questions.shift().question);
            }
        }
    }

    answer() {
        if(this.preview){
            this.nextQuestion(true);
            if(this.completed_questions < this.total_questions)
              this.completed_questions++;
            return;
        }
        if (this.selected.hasOwnProperty('option') && !this.utill.isObjectEmpty(this.selected.option)) {
            this.utill.showLoadingHandler("Checking your answer!");
            // setTimeout(() => {
              this.utill.getStorage(this.utill.tempSectionCode).then(val=>{

                this.answerService.answerQuestion({
                    student_answer: this.selected.option,
                    question_id: this.current_question._id,
                    section_code: this.utill.parseJSON(val)//this.utill.sessionStorage.get(this.utill.tempSectionCode),
                    // student_id : $window.sessionStorage.identification_id
                })
                    .subscribe((resp:any)=>{
                        this.utill.hideLoadingHandler();
                        if (resp.success) {
                            this.correct_answer = resp.correct_answer;
                            // answerService.history.addAnswer(this.quiz_data.quiz_id,this.quiz_data.section_id, resp.correctness);
                            this.question_answered = true;
                        } else {
                            if (resp.hasOwnProperty('end_session'))
                            	this.router.navigate(['members','l','quiz_result'],{state:{
                            		quiz_id: this.quiz_data.quiz_id,
                                    section_id: this.quiz_data.section_id
                            	}});
                            this.utill.showMessage(resp);
                        }
                    })
            })
        } else {
            this.utill.showError("Please select an answer!");
        }
    }

}
