import { Component, OnInit, NgZone } from '@angular/core';
import { NavController, ModalController, ActionSheetController } from '@ionic/angular';
import { UserService } from './../../../services/user.service';
import { AlertController, Platform } from '@ionic/angular';
import { AuthenticationService, User } from './../../../services/authentication.service';
import { UtilService } from './../../../services/util.service';

function getWindow (): any {
  return window;
}

@Component({
  selector: 'app-scan-bluetooth',
  templateUrl: './scan-bluetooth.component.html',
  styleUrls: ['./scan-bluetooth.component.scss'],
})
export class ScanBluetoothComponent implements OnInit {
  isScanning: boolean = false;
  window :any;
  devices : any = [];
  user: any;

  constructor(
  	private util: UtilService,
  	private userService: UserService,
  	private modalCtrl: ModalController,
  	private platform: Platform,
  	private authService: AuthenticationService,
  	private zone: NgZone
  ) { }

  ngOnInit() {
  	var that = this;
    that.window = getWindow();

  	this.authService.currentUser.subscribe(user => {
      this.user = user;
    });
  	this.startScanningForBeacons();
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  stopBeaconScan(){
    this.isScanning = false;
    this.window.evothings.eddystone.stopScan(()=>{
      console.log("Scan Stopped!");
    })
  }

  startScanningForBeacons(){
  	var that = this;
    this.platform.ready().then(() => {
      if (this.platform.is('cordova')) {
        setTimeout(()=>{
          this.window.evothings.eddystone.stopScan(()=>{
            console.log("Scan stopped!");
          })
        },10000);
        this.isScanning = true;
        this.window.evothings.eddystone.startScan((device) => {
          if(device.hasOwnProperty('bid')){
              if(that.devices.indexOf(device) == -1)
              	that.devices.push(device);
              this.zone.run(() => {console.log(that.devices)});
          }
        },
        error =>console.error('error: ', error))
      }
    });
  }

  int8ArrayToString(uint8array){
    return this.util.uint8ArrayToString(uint8array)
  }

  assignBeacon(device){
  	var data = {
        beacon_id : this.util.uint8ArrayToString(device.bid),
        name : device.name,
        url : device.url
    };
    this.userService.updateBeacon(data).subscribe((resp:any) =>{
        this.util.showMessage(resp);
        if(resp.success){
            this.user.beacon = resp.beacon;
            this.stopBeaconScan();
        }
    })
  }

}
