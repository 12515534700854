import { Component, OnInit } from '@angular/core';
import { StudentService } from './../../services/student.service';

@Component({
  selector: 'app-student-upcoming-classes',
  templateUrl: './student-upcoming-classes.component.html',
  styleUrls: ['./student-upcoming-classes.component.scss'],
})
export class StudentUpcomingClassesComponent implements OnInit {
  sessions = [];
  loading= false;

  constructor(private studentService: StudentService) { }

  ngOnInit() {
  	this.loadClass();
  }

  loadClass(){
  	this.loading = true;
  	this.studentService.getUpcomingClasses().subscribe((resp: any) => {
        let className = '';
        let instructor = '';
        if(resp && resp.success){
        	resp.classes.forEach(c =>{
            className = c.tags.course[0].name;
            instructor = c.user_id.first_name + ' ' + c.user_id.last_name;
            
            c.sessions.forEach(session => {
              session.course = className;
              session.instructor = instructor;
              this.sessions.push(session);
            })

        	});
        }
        this.loading = false;
        this.sessions.sort((a, b) => a.start.localeCompare(b.start));
    });

  }

}
