import { Component, OnInit, Input } from '@angular/core';
import { PopoverController, ModalController, Events } from '@ionic/angular';
import { UserService } from './../../../services/user.service';
import { UtilService } from './../../../services/util.service';

@Component({
  selector: 'app-update-name',
  templateUrl: './update-name.component.html',
  styleUrls: ['./update-name.component.scss'],
})
export class UpdateNameComponent implements OnInit {
  @Input() user;
  firstName;
  lastName;

  constructor(
    private popoverCtrl: PopoverController, 
    private userService: UserService,
    private util: UtilService,
    private modalCtrl: ModalController,
    private events: Events
  ) { }

  ngOnInit() {
    this.firstName = this.user.first_name;
    this.lastName = this.user.last_name;
  }

  changeName() {
    if(this.util.isObjectEmpty(this.firstName) || this.util.isObjectEmpty(this.lastName)){
      this.util.showError("You must fill up both fields");
    } else {
      this.userService.changeName({first_name: this.firstName, last_name: this.lastName}).subscribe((resp:any) => {
        if(resp && resp.success){
          this.firstName = resp.data.first_name;
          this.lastName = resp.data.last_name;
          this.events.publish('nameUpdated', {name: this.firstName + ' ' + this.lastName});
          this.modalCtrl.dismiss(resp.data);
        }
        this.util.showMessage(resp);
      });
    }
  }

  closeModal() {
		this.modalCtrl.dismiss();
	}
}

