import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { UtilService } from './util.service';

@Injectable({
  providedIn: 'root'
})
export class QuizService {

  constructor(
    private _http: HttpClient,
    private util: UtilService
  ) { }


  getQuizById(quiz_id) {
    return this._http.get(this.util.url + '/api-v2/quizzes/' + quiz_id);
  }

  getQuizByCode(code) {
    return this._http.get(this.util.url + '/api/quizzes/code/' + code);
  }

  takequiz(data){
    return this._http.post(this.util.url+'/api-v2/take_quiz', data);
  }

  takingQuiz(){
    return this._http.get(this.util.url+'/api-v2/take_quiz/taking-quiz');
  }

  loadTerm(code){
  	return this._http.get(this.util.url+'/api/terms/code/'+code);
  }

  can_take_quiz(section_code){
    return this._http.get(this.util.url + '/api/take_quiz/can-take-quiz/'+section_code);
  }

  searchQuizzes(data){
  	return this._http.post(this.util.url+'/api/quizzes/search',data);
  }

  count(){
  	return this._http.get(this.util.url+'/api-v2/quizzes/count');
  }

  lastList(){
    return this._http.get(this.util.url+'/api-v2/quizzes/last-list');
  }

  activeQuizzes(){
  	return this._http.get(this.util.url+'/api-v2/quizzes/active');
  }

  createQuestionwithCSV(file) {
    var data = new FormData()
    var url = this.util.url + '/api/questions/uploadquestions'
    data.append('file', file)

    return this._http.post(url, data,{})
  }

  deleteQuestion(question_id){
    return this._http.delete(this.util.url+'/api/questions/'+question_id);
  }

  clone(quiz_id,name){
    return this._http.get(this.util.url+'/api-v2/quizzes/clone/'+quiz_id+'/'+name);
  }

  update(quiz){
    return this._http.put(this.util.url+'/api/quizzes',quiz);
  }

  updateQuizNoQuestions(quiz){
    return this._http.put(this.util.url+'/api-v2/quizzes',quiz);
  }

  save(quiz){
    return this._http.post(this.util.url+'/api-v2/quizzes',quiz);
  }

  report(quiz_id){
    return this._http.get(this.util.url+'/api/generate_report/quiz='+quiz_id)
  }

  sectionReport(section_code,term_id){
    return this._http.get(this.util.url+'/api/generate_report/section='+section_code+'&term='+term_id)
  }

  //section id or code
  sectionStats(section_id){
    return this._http.get(this.util.url+'/api-v2/sections/'+section_id+'/stats');
  }

  startQuiz(quiz_id, track_time, beacon, allowed_ids, term_id){
    return this._http.post(this.util.url+'/api-v2/sections/'+quiz_id,{
      time_tracking_enabled:track_time,
      enable_beacon:beacon,
      allowed_ids,
      term_id
    });
  }

  stopQuiz(section_code){
    return this._http.post(this.util.url+'/api-v2/sections/stop_section/'+section_code,{});
  }

  updateNotification(quiz_id,notification_status){
    return this._http.put(this.util.url+'/api/notifications/'+quiz_id,{'notification':notification_status});
  }

  getPreviewQuiz(quiz_id){
    return this._http.get(this.util.url+'/api-v2/take_quiz/preview-quiz/'+quiz_id);
  }

  sectionTimeTracking(section_id,status){
    return this._http.post(this.util.url+'/api/sections/time_tracking/'+section_id,{'time_tracking_enabled':status});
  }

  sectionBeaconUsage(section_id,status){
    return this._http.get(this.util.url+'/api/sections/update-beacon/'+section_id+'/'+status);
  }

  sectionAllowedIds(section_id,allowed_ids){
    return this._http.put(this.util.url+'/api-v2/sections/'+section_id+'/add-allowed-id',{'ids':allowed_ids});
  }

  sectionAllowedBeacons(section_id,beacon_ids){
    return this._http.put(this.util.url+'/api-v2/sections/update-beacon/'+section_id,{'beacons_ids':beacon_ids}); 
  }

  sectionAllBeacons(section_id){
    return this._http.get(this.util.url+'/api-v2/sections/'+section_id+'/beacons');
  }

  query(query,page){
    return this._http.get(this.util.url+'/api/quizzes/query='+query+'&'+page);
  }

  getServerDatetime(){
    return this._http.get(this.util.url+'/api-v2/quizzes/datetime');
  }
}
