import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'safeUrl'
})
export class SafeUrlPipe implements PipeTransform {

  constructor(private domSanitizer: DomSanitizer) {}

  transform(value: any, args?: any): any {
  	var result = this.domSanitizer.bypassSecurityTrustResourceUrl(value);
  	result = this.domSanitizer.sanitize(SecurityContext.URL, result);
    return result;
    //bypassSecurityTrustHtml(value);
  }

}